import { Badge, Col, Row, Space, Spin, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CardTitleLargeSpan,
  GraySpan,
  DashboardContainer,
  ColoredTag,
  GreenSpan,
  GrayItalicSpan,
} from 'components/Custom/spans';
import { RoundInput } from 'components/Custom/inputs';
import { ViesInfo } from 'store/vat-checker/models';
import { ReactComponent as RightArrowGreenIcon } from 'assets/images/icons/rightArrowGreenIcon.svg';
import { ReactComponent as RedWarningIcon } from 'assets/images/icons/redWarningIcon.svg';
import { ReactComponent as PadLockIcon } from 'assets/images/icons/padLockIcon.svg';
import { ReactComponent as IconRefresh } from 'assets/images/icons/iconRefresh.svg';
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { ExportButton, ScopeButton } from 'components/Custom/buttons';
import { EmptyPage } from 'containers/EmptyPage';
import { getProviderLink } from 'utils/get-provider-link';
import { User } from 'store/auth/models';
import { AccountType } from 'store/api/enums';

interface QuilifiedEntitiesProps {
  viesInfos: ViesInfo[];
  loadCustomersAndSuppliers: (connectionId: string) => void;
  activateVatChecker: (connectionId: string, entityName: string) => void;
  loading: boolean;
  onEditEntity: (viesInfo: ViesInfo) => void;
  hideAll: boolean;
  currentUser?: User;
}

const { Text } = Typography;

export const QualifiedEntities: React.FC<QuilifiedEntitiesProps> = ({
  viesInfos,
  loadCustomersAndSuppliers,
  activateVatChecker,
  loading,
  onEditEntity,
  hideAll,
  currentUser,
}) => {
  const { t } = useTranslation();
  const [source, setSource] = React.useState(viesInfos);

  React.useEffect(() => {
    viesInfos && viesInfos !== source && setSource(viesInfos);
  }, [viesInfos]);

  const search = (e) => {
    const value = e.target.value;
    if (value != '') {
      const filterTable = viesInfos?.filter((o) =>
        String(o['organisationName'])
          .toLowerCase()
          .includes(value.toLowerCase())
      );

      setSource(filterTable);
    } else {
      setSource(viesInfos);
    }
  };

  const sortQuilifiedEntities = (data) => {
    const sortedByName = data?.sort((a, b) =>
      a.organisationName.localeCompare(b.organisationName)
    );
    const sortedByStatus = sortedByName.sort((a, b) => {
      if (a.vatCheckerActive && !b.vatCheckerActive) {
        return -1;
      } else if (!a.vatCheckerActive && b.vatCheckerActive) {
        return 1;
      } else {
        return 0;
      }
    });
    return sortedByStatus;
  };

  return (
    <Spin spinning={loading} size="large">
      <Row
        style={{
          padding: '35px 0px',
          borderTop: '1px solid #DBEAE3',
          justifyContent: 'space-between',
          marginTop: '5px',
        }}
      >
        <Col style={{ display: 'flex', flexDirection: 'column' }}>
          <CardTitleLargeSpan style={{ marginBottom: 10 }}>
            {t('vat-checker.activated-entities')}
          </CardTitleLargeSpan>
        </Col>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <RoundInput
            width={'266px'}
            height={'40px'}
            prefix={<SearchOutlined />}
            size="large"
            placeholder={'Search Entities'}
            onChange={search}
          />
        </Col>
      </Row>
      {source.filter((entity) => entity.vatCheckerActive).length == 0 ? (
        <>
          {!hideAll ? (
            <EmptyPage
              titleText="Activate a qualified entity from the list below to start using the VAT Number Checker"
              actionText=""
              messageOnly
            />
          ) : (
            <div style={{ height: '100vh' }}></div>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              marginBottom: '25px',
              flexWrap: 'wrap',
              rowGap: '25px',
              columnGap: 20,
            }}
          >
            {sortQuilifiedEntities(
              source.filter((entity) => entity.vatCheckerActive)
            ).map((vi) => (
              <DashboardContainer
                key={vi.connectionId}
                className={'active-container'}
                onClick={() => loadCustomersAndSuppliers(vi.connectionId)}
                style={{ zIndex: 0 }}
              >
                <Row
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '25px',
                    //cursor: vi.vatCheckerActive ? "pointer" : "default",
                  }}
                >
                  <Col style={{ display: 'flex' }}>
                    <Space>
                      <a
                        href={getProviderLink(
                          vi.providerName.toLowerCase(),
                          vi.shortCode
                        )}
                        target="_blank"
                        rel="noreferrer"
                        style={{ height: '32px', display: 'block' }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <img
                          src={
                            vi.providerName
                              ? require(`shared/assets/images/${vi.providerName.toLowerCase()}_small.png`)
                                  .default
                              : ''
                          }
                          width={30}
                        />
                      </a>
                      <div
                        style={{
                          width: '170%',
                        }}
                      >
                        <Text
                          className="container-title"
                          style={{ maxWidth: 140 }} //@ts-ignore
                          ellipsis={{ rows: 1, tooltip: true }}
                        >
                          {vi.organisationName}
                        </Text>
                      </div>
                      <div
                        className="edit-details-link"
                        style={{ paddingLeft: '10px', zIndex: 10 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditEntity(vi);
                        }}
                      >
                        <EditOutlined />
                      </div>
                    </Space>
                  </Col>
                  <Col>
                    <RightArrowGreenIcon />
                  </Col>
                </Row>
                <Row
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '25px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '38%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:
                          vi.supplierCount < 100
                            ? 65
                            : vi.supplierCount < 1000
                            ? 75
                            : vi.supplierCount < 10000
                            ? 85
                            : 115,
                      }}
                    >
                      <Badge
                        count={
                          vi.hasInvalidSupplier ? <RedWarningIcon /> : null
                        }
                        offset={[2, 3]}
                      >
                        <Text className="container-number-span">
                          {vi.supplierCount}
                        </Text>
                      </Badge>
                      <GreenSpan>{t('vat-checker.suppliers')}</GreenSpan>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:
                          vi.customerCount < 100
                            ? 65
                            : vi.customerCount < 1000
                            ? 75
                            : vi.customerCount < 10000
                            ? 85
                            : 115,
                      }}
                    >
                      <Badge
                        count={
                          vi.hasInvalidCustomer ? <RedWarningIcon /> : null
                        }
                        offset={[2, 3]}
                      >
                        <Text className="container-number-span">
                          {vi.customerCount}
                        </Text>
                      </Badge>
                      <GreenSpan>{t('vat-checker.customers')}</GreenSpan>
                    </div>
                  </div>
                  <Space direction="vertical" style={{ rowGap: 2 }}>
                    {vi.vatCheckerActive && (
                      <>
                        {vi.hasInvalidCustomer || vi.hasInvalidSupplier ? (
                          <>
                            <ColoredTag
                              style={{ margin: 0 }}
                              color="#D13131" //@ts-ignore
                              bgcolor="#FCE7E7"
                              bordered={true}
                              icon={
                                <CloseOutlined style={{ color: '#D13131' }} />
                              }
                            >
                              {t('main.error')}
                            </ColoredTag>
                          </>
                        ) : (
                          <>
                            <ColoredTag
                              style={{ margin: 0 }}
                              color="#058181" //@ts-ignore
                              bgcolor="#E9F6F7"
                              bordered={true}
                              bordercolor="#DBEAE3"
                              icon={<CheckOutlined />}
                            >
                              {t('main.valid')}
                            </ColoredTag>
                          </>
                        )}
                        <GrayItalicSpan //@ts-ignore
                          fontSize="11px"
                          color="#879494"
                        >
                          {vi.lastViesCheckDate &&
                            moment(vi.lastViesCheckDate).fromNow()}
                        </GrayItalicSpan>
                      </>
                    )}
                  </Space>
                </Row>
              </DashboardContainer>
            ))}
          </div>
        </>
      )}

      {source.filter((entity) => !entity.vatCheckerActive).length > 0 && (
        <>
          <Row
            style={{
              padding: '35px 0px',
              borderTop: '1px solid #DBEAE3',
              justifyContent: 'space-between',
            }}
          >
            <Col style={{ display: 'flex', flexDirection: 'column' }}>
              <CardTitleLargeSpan style={{ marginBottom: 10 }}>
                {t('vat-checker.qualified-entities')}
              </CardTitleLargeSpan>
              <GraySpan>
                Activate the entity to find out which customers and suppliers
                are giving an error. VAT Numbers are checked overnight and every
                time there is an Invoice or Order.
              </GraySpan>
            </Col>
          </Row>
          <div
            style={{
              display: 'flex',
              marginBottom: '25px',
              flexWrap: 'wrap',
              rowGap: '25px',
              columnGap: 20,
            }}
          >
            {sortQuilifiedEntities(
              source.filter((entity) => !entity.vatCheckerActive)
            ).map((vi) => (
              <DashboardContainer
                key={vi.connectionId}
                className={'inactive-container'}
              >
                <Row
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '25px',
                    cursor: 'default',
                  }}
                >
                  <Col>
                    <Space>
                      <a
                        href={getProviderLink(
                          vi.providerName.toLowerCase(),
                          vi.shortCode
                        )}
                        target="_blank"
                        rel="noreferrer"
                        style={{ height: '32px', display: 'block' }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <img
                          src={
                            vi.providerName
                              ? require(`shared/assets/images/${vi.providerName.toLowerCase()}_small.png`)
                                  .default
                              : ''
                          }
                          width={30}
                        />
                      </a>
                      <div
                        style={{
                          width: '170%',
                        }}
                      >
                        <Text
                          className="container-title"
                          style={{ maxWidth: 140 }} //@ts-ignore
                          ellipsis={{ rows: 1, tooltip: true }}
                        >
                          {vi.organisationName}
                        </Text>
                      </div>
                      <div
                        className="edit-details-link"
                        style={{ paddingLeft: '10px', zIndex: 10 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditEntity(vi);
                        }}
                      >
                        <EditOutlined />
                      </div>
                    </Space>
                  </Col>
                  <Col>
                    <PadLockIcon />
                  </Col>
                </Row>
                <Row
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '25px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '38%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:
                          vi.supplierCount < 100
                            ? 65
                            : vi.supplierCount < 1000
                            ? 75
                            : vi.supplierCount < 10000
                            ? 85
                            : 115,
                      }}
                    >
                      <Badge
                        count={
                          vi.hasInvalidSupplier ? <RedWarningIcon /> : null
                        }
                        offset={[2, 3]}
                      >
                        <Text className="container-number-span">
                          {vi.supplierCount}
                        </Text>
                      </Badge>
                      <GreenSpan>{t('vat-checker.suppliers')}</GreenSpan>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth:
                          vi.customerCount < 100
                            ? 65
                            : vi.customerCount < 1000
                            ? 75
                            : vi.customerCount < 10000
                            ? 85
                            : 115,
                      }}
                    >
                      <Badge
                        count={
                          vi.hasInvalidCustomer ? <RedWarningIcon /> : null
                        }
                        offset={[2, 3]}
                      >
                        <Text className="container-number-span">
                          {vi.customerCount}
                        </Text>
                      </Badge>
                      <GreenSpan>{t('vat-checker.customers')}</GreenSpan>
                    </div>
                  </div>
                  <Space direction="vertical" style={{ rowGap: 2 }}>
                    {!vi.vatCheckerActive &&
                      currentUser &&
                      AccountType[currentUser?.accountType] !==
                        AccountType.CLIENT && (
                        <>
                          <br />
                          <ScopeButton
                            type="default" //@ts-ignore
                            width="78px"
                            height="25px"
                            fontSize="12px"
                            lineheight="12px"
                            onClick={() =>
                              activateVatChecker(
                                vi.connectionId,
                                vi.organisationName
                              )
                            }
                          >
                            Activate
                          </ScopeButton>
                        </>
                      )}
                  </Space>
                </Row>
              </DashboardContainer>
            ))}
          </div>
        </>
      )}
      {/* </div> */}
    </Spin>
  );
};
