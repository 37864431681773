import { takeLatest } from 'redux-saga/effects';
import {
  ACTIVATE_VIES_REQUEST,
  CHECK_CONTACT_REQUEST,
  CHECK_CONTACTS_REQUEST,
  DEACTIVATE_VIES_REQUEST,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACTS_REQUEST,
  FETCH_VIES_INFO_REQUEST,
  HIDE_CONTACT_REQUEST,
  REFRESH_CONTACT_REQUEST,
  REFRESH_CONTACTS_REQUEST,
} from '../constants';
import { fetchViesInfoSaga } from './fetchViesInfoSaga';
import { activateViesSaga } from './activateViesSaga';
import { fetchContactsSaga } from './fetchContactsSaga';
import { fetchContactSaga } from './fetchContactSaga';
import { checkContactsSaga } from './checkContactsSaga';
import { checkContactSaga } from './checkContactSaga';
import { refreshContactsSaga } from './refreshContactsSaga';
import { refreshContactSaga } from './refreshContactSaga';
import { deactivateViesSaga } from './deactivateViesSaga';
import { hideContactSaga } from './hideContactsSaga';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* vatCheckerSaga() {
  yield takeLatest(FETCH_VIES_INFO_REQUEST, fetchViesInfoSaga);
  yield takeLatest(ACTIVATE_VIES_REQUEST, activateViesSaga);
  yield takeLatest(DEACTIVATE_VIES_REQUEST, deactivateViesSaga);
  yield takeLatest(FETCH_CONTACTS_REQUEST, fetchContactsSaga);
  yield takeLatest(FETCH_CONTACT_REQUEST, fetchContactSaga);
  yield takeLatest(CHECK_CONTACTS_REQUEST, checkContactsSaga);
  yield takeLatest(CHECK_CONTACT_REQUEST, checkContactSaga);
  yield takeLatest(REFRESH_CONTACTS_REQUEST, refreshContactsSaga);
  yield takeLatest(REFRESH_CONTACT_REQUEST, refreshContactSaga);
  yield takeLatest(HIDE_CONTACT_REQUEST, hideContactSaga);
}
