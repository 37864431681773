import { Col, Row, Space, Spin, Switch, Popover } from 'antd';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { User } from 'store/auth/models';
import { ReactComponent as EllipseIcon } from 'assets/images/icons/ellipsesIcon.svg';
import { ReactComponent as EyeIcon } from 'assets/images/icons/eyeIcon.svg';
import { ReactComponent as HistoryIcon } from 'assets/images/icons/historyIcon.svg';
import { ReactComponent as ToggleIcon } from 'assets/images/icons/toggleIcon.svg';
import { ReactComponent as CrossIcon } from 'assets/images/icons/crossIcon.svg';
import { AccountType, PermissionType } from 'store/api/enums';
import { ScopeTable } from 'components/Custom/table';
import { BoldNameSpan, ColoredTag } from 'components/Custom/spans';
import {
  ScopeButton,
  ContextButton,
  IconContextButton,
} from 'components/Custom/buttons';
import { AvatarSizes, renderAvatar } from 'utils/render-avatar';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { StyledPopconfirm } from 'components/Custom/Popconfirm';
import { StyledTooltip } from 'components/Custom/Tooltip';
import { Preference, PreferenceActions } from 'utils/preferences';
import { PAGE_SIZE_USERS } from 'pages/users';

interface UsersComponentProps {
  loading: boolean;
  error?: string;
  members?: User[];
  currentUser?: User;
  saveUserPermissions: (
    accountUuid: string,
    permissionType: PermissionType,
    enabled: boolean
  ) => void;
  openMemberWindow: () => void;
  openHistoryWindow: (userId: string, userName: string) => void;
  openRightsWindow: (accountId: string, userName: string) => void;
  deleteUser: (userToDeleteId: string) => void;
  resendActivation: (email: string) => void;
  goBack: () => void;
  getPicture: (userUuid: string) => void;
  handlePreferences: (action: PreferenceActions, preference: Preference) => any;
}
const renderRole = (content: string, record: User) => {
  switch (AccountType[content]) {
    case AccountType.OWNER:
      return (
        <ColoredTag
          color="#042A2A" //@ts-ignore
          bgcolor="#FBE3FF"
        >
          {AccountType[content]}
        </ColoredTag>
      );
      break;
    case AccountType.CLIENT:
      return (
        <ColoredTag
          color="#042A2A" //@ts-ignore
          bgcolor="#CCF5FE"
        >
          {AccountType[content]}
        </ColoredTag>
      );
      break;
    case AccountType.STAFF:
      return (
        <ColoredTag
          color="#042A2A" //@ts-ignore
          bgcolor="#F9EEC7"
        >
          {AccountType[content]}
        </ColoredTag>
      );
      break;
    default:
      break;
  }
};

export const UsersComponent: React.FC<UsersComponentProps> = ({
  loading,
  members,
  currentUser,
  saveUserPermissions,
  openMemberWindow,
  openHistoryWindow,
  openRightsWindow,
  deleteUser,
  resendActivation,
  goBack,
  getPicture,
  handlePreferences,
}) => {
  const pageSizeSaved = handlePreferences(PreferenceActions.GET, {
    name: PAGE_SIZE_USERS,
  });
  const { t } = useTranslation();
  const [permissions, setPermissions] = React.useState<any>();

  React.useEffect(() => {
    const perms = {};

    members?.map(
      (m) =>
        (perms[m.accountUuid] = {
          orgs: m.canManageOrgs,
          users: m.canManageUsers,
        })
    );
    setPermissions(perms);
  }, []);

  const addMember = () => {
    openMemberWindow();
  };

  const onBackClick = () => {
    goBack();
  };

  const renderLastLogin = (_: string, record: User) => {
    if (record.accountActivated) {
      const value = moment(record.lastLogin).fromNow();
      return <>{value}</>;
    } else {
      return <>{t('common.never')}</>;
    }
  };

  const renderManageOrgs = (_: string, record: User) => {
    if (
      !record.isLicenseOwner &&
      AccountType[record.accountType] === AccountType.STAFF
    )
      return (
        <Switch
          className={
            permissions && permissions[record.accountUuid]?.orgs
              ? 'switch-checked'
              : ''
          }
          checked={permissions ? permissions[record.accountUuid]?.orgs : false}
          onChange={(e) => {
            saveUserPermissions(
              record.accountUuid,
              PermissionType.ORGANISATIONS,
              e
            );

            const perm = { ...permissions };
            perm[record.accountUuid] = { ...perm[record.accountUuid], orgs: e };
            setPermissions(perm);
          }}
        />
      );
  };

  const renderManageUsers = (_: string, record: User) => {
    if (
      !record.isLicenseOwner &&
      AccountType[record.accountType] === AccountType.STAFF
    )
      return (
        <Switch
          className={
            permissions && permissions[record.accountUuid]?.users
              ? 'switch-checked'
              : ''
          }
          checked={permissions ? permissions[record.accountUuid]?.users : false}
          onChange={(e) => {
            saveUserPermissions(record.accountUuid, PermissionType.USERS, e);

            const perm = { ...permissions };
            perm[record.accountUuid] = {
              ...perm[record.accountUuid],
              users: e,
            };
            setPermissions(perm);
          }}
        />
      );
  };

  const renderContextMenu = (record: User) => {
    // const to = `/entities/organisation/${record.organisationId}`;
    const profile = `/profile/${record.accountUuid}`;
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          <IconContextButton
            type="text"
            icon={
              <EyeIcon
                style={{ width: '18px', height: '22px', marginRight: '4px' }}
              />
            }
          >
            <Link style={{ textDecoration: 'none' }} to={profile}>
              {t('users.view-details')}
            </Link>
          </IconContextButton>
          {record.accountActivated && record.lastLogin && (
            <IconContextButton
              type="text"
              onClick={() =>
                openHistoryWindow(
                  record.userUuid,
                  record.firstName + ' ' + record.lastName
                )
              }
              icon={
                <HistoryIcon
                  style={{
                    width: '18px',
                    height: '20px',
                    marginRight: '5px',
                  }}
                />
              }
            >
              {t('users.login-history')}
            </IconContextButton>
          )}
          {!record.isLicenseOwner &&
            record.userUuid !== currentUser?.userUuid && (
              <>
                {currentUser?.canManageUsers && (
                  <IconContextButton
                    type="text"
                    onClick={() =>
                      openRightsWindow(
                        record.accountUuid,
                        record.firstName + ' ' + record.lastName
                      )
                    }
                    icon={
                      <ToggleIcon
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '3px',
                        }}
                      />
                    }
                  >
                    {t('users.access-rights')}
                  </IconContextButton>
                )}
                {currentUser?.canManageUsers && (
                  <StyledPopconfirm // @ts-ignore
                    title={t('users.delete-user-title')}
                    description={
                      <span>
                        {t('users.delete-user-description').split('{br}')[0]}
                        <br />
                        {t('users.delete-user-description').split('{br}')[1]}
                      </span>
                    }
                    onConfirm={() => onDeleteUser(record.userUuid)}
                    placement="left"
                    okText={t('common.yes')}
                    cancelText={t('common.cancel')}
                  >
                    <IconContextButton
                      type="text"
                      icon={
                        <CrossIcon
                          style={{
                            width: '10px',
                            height: '22px',
                            marginRight: '8px',
                            marginLeft: '5px',
                          }}
                        />
                      }
                    >
                      {t('users.delete-user')}
                    </IconContextButton>
                  </StyledPopconfirm>
                )}
              </>
            )}
        </Space>
      </div>
    );
  };

  const renderButtons = (_: string, record: User) => {
    if (
      AccountType[record.accountType] === AccountType.OWNER &&
      currentUser?.userUuid !== record.userUuid
    )
      return;
    return (
      <Popover
        placement="topLeft"
        arrow={false}
        trigger="click"
        content={renderContextMenu(record)}
        title={''}
        overlayStyle={{ zIndex: 999 }}
      >
        <ContextButton type="text">
          <EllipseIcon
            style={{
              height: '10px',
            }}
          />
        </ContextButton>
      </Popover>
    );
  };

  const onDeleteUser = (userToDelete: string) => {
    deleteUser(userToDelete);
  };

  const renderUserColumns = (_, record: User) => {
    const profile = `/profile/${record.accountUuid}`;
    return (
      <Space>
        {renderAvatar(
          record.firstName,
          record.lastName,
          AvatarSizes.DEFAULT,
          record.userUuid,
          record?.profilePicture
        )}
        <Space direction="vertical" style={{ rowGap: '0' }}>
          {AccountType[record.accountType] === AccountType.OWNER &&
          currentUser?.userUuid !== record.userUuid ? (
            <BoldNameSpan>{`${record.firstName} ${record.lastName}`}</BoldNameSpan>
          ) : (
            <Link to={profile}>
              <BoldNameSpan>{`${record.firstName} ${record.lastName}`}</BoldNameSpan>
            </Link>
          )}
          <BoldNameSpan //@ts-ignore
            fontWeight={400}
          >
            {record.email}
          </BoldNameSpan>
        </Space>
      </Space>
    );
  };

  const renderCreatedUser = (_, record: User) => {
    const creatorNames = record.accountCreatorName?.split(' ');
    return (
      <Space>
        {creatorNames &&
          renderAvatar(
            creatorNames[0],
            creatorNames?.length > 0 ? creatorNames[1] : '',
            AvatarSizes.DEFAULT,
            record.userUuid
            //record?.profilePicture
          )}
        <Space direction="vertical" style={{ rowGap: '0' }}>
          <BoldNameSpan //@ts-ignore
            fontWeight={400}
          >{`${record.accountCreatorName}`}</BoldNameSpan>
        </Space>
      </Space>
    );
  };

  const renderStatus = (content: string, record: User) => {
    if (record.accountActivated) {
      return (
        <ColoredTag
          color="#058181" //@ts-ignore
          bgcolor="#E9F6F7"
          bordered={true}
          bordercolor="#DBEAE3"
          icon={<CheckOutlined />}
        >
          {t('users.active')}
        </ColoredTag>
      );
    } else {
      return (
        <ScopeButton //@ts-ignore
          width="113px"
          height="25px"
          fontSize="12px"
          lineheight="12px"
          onClick={() => resendActivation(record.email)}
          type="primary"
        >
          {t('users.resend-email')}
        </ScopeButton>
      );
    }
  };

  const renderSortIcon = ({ sortOrder }) => {
    if (sortOrder == 'ascend') {
      return <ArrowDownOutlined style={{ color: '#879494' }} />;
    } else {
      return <ArrowUpOutlined style={{ color: '#879494' }} />;
    }
  };

  const getManageTitle = (isOrg) => {
    return (
      <>
        When choosing this option, the user will be able to:
        {isOrg ? (
          <ul>
            <li>add new entities</li>
            <li>edit existing ones (such as assigning a friendly name)</li>
            <li>disconnect an entity</li>
            <li>view usage and properties</li>
          </ul>
        ) : (
          <ul>
            <li>add new users and resend invites</li>
            <li>view login history</li>
            <li>assign permissions & rights to entities</li>
            <li>delete users</li>
          </ul>
        )}
      </>
    );
  };

  const renderManageOrgsOrUsersTitle = (isOrg) => {
    return (
      <>
        {isOrg ? t('users.manage-orgs') : t('users.manage-users')}
        {'   '}
        <StyledTooltip // @ts-ignore
          title={getManageTitle(isOrg)}
          placement="top"
          arrow={false}
          width="395px"
          trigger={['hover', 'click']}
        >
          <ExclamationCircleOutlined />
        </StyledTooltip>
      </>
    );
  };

  const columns = [
    {
      title: t('users.user'),
      dataIndex: 'fullName',
      key: 'fullName',
      render: renderUserColumns,
      sorter: (a, b) => a.firstName?.localeCompare(b.firstName),
      sortIcon: renderSortIcon,
    },
    {
      title: t('users.accountType'),
      dataIndex: 'accountType',
      key: 'accountType',
      render: renderRole,
      sorter: (a, b) =>
        AccountType[a.accountType].localeCompare(AccountType[b.accountType]),
      sortIcon: renderSortIcon,
    },
    {
      title: t('users.last-login'),
      dataIndex: 'lastLogin ',
      key: 'lastLogin',
      render: renderLastLogin,
      sorter: (a, b) =>
        new Date(a.lastLogin).valueOf() - new Date(b.lastLogin).valueOf(),
      sortIcon: renderSortIcon,
    },
    {
      title: renderManageOrgsOrUsersTitle(true),
      dataIndex: 'canManageOrgs',
      key: 'canManageOrgs',
      render: (c, r: User) =>
        currentUser?.userUuid !== r.userUuid && renderManageOrgs(c, r),
    },
    {
      title: renderManageOrgsOrUsersTitle(false),
      dataIndex: 'canManageUsers',
      key: 'canManageUsers',
      render: (c, r: User) =>
        currentUser?.userUuid !== r.userUuid && renderManageUsers(c, r),
    },
    {
      title: t('users.accountCreatorName'),
      dataIndex: 'accountCreatorName',
      key: 'accountCreatorName',
      render: renderCreatedUser,
      sorter: (a, b) =>
        a.accountCreatorName?.localeCompare(b.accountCreatorName),
      sortIcon: renderSortIcon,
    },
    {
      title: t('users.accountActivated'),
      dataIndex: 'accountActivated',
      key: 'accountActivated',
      render: renderStatus,
    },
    {
      render: renderButtons,
    },
  ];

  const renderAddUserButton = () => {
    return (
      currentUser?.canManageUsers && (
        <ScopeButton //@ts-ignore
          width="117px"
          height="32px"
          fontSize="13px"
          lineheight="12px"
          onClick={addMember}
          type="primary"
        >
          {t('users.add-user')}
        </ScopeButton>
      )
    );
  };

  const savePageSize = (pageSize: number) => {
    handlePreferences(PreferenceActions.SET, {
      name: PAGE_SIZE_USERS,
      value: pageSize,
    });
  };

  return (
    <div
      style={{
        padding: '40px',
        paddingTop: '10px',
        maxWidth: 1600,
        minWidth: 1320,
        margin: 'auto',
      }}
    >
      <Spin spinning={loading} size="large">
        <Row justify="center" gutter={[0, 16]}>
          <Col span={24}>
            <ScopeTable //@ts-ignore
              columns={columns}
              mainLocaleKey="users"
              filters={[
                'accountType',
                'accountActivated',
                'accountCreatorName',
              ]} //@ts-ignore
              originalDataSource={members} //@ts-ignore
              tableDataSource={members} //@ts-ignore
              pagination={true}
              rowsPerPage={pageSizeSaved ? pageSizeSaved : 20}
              enableColumnsCustomization={false}
              rowHeight={60} //@ts-ignore
              renderRightComponent={renderAddUserButton()}
              haveBorder
              showSizeChanger
              getNewPageSize={savePageSize}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
