import { call, put } from 'redux-saga/effects';
import { exceptionsActionFailure, exceptionsActionSuccess } from '../actions';
import { ApiError } from 'store/api/types';
import { ExceptionsActionRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { exceptionsAction } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* saveExceptionsActionSaga(
  action: ExceptionsActionRequestAction
) {
  try {
    let err: ApiError | null;
    const { dataLogId, mappingId, exceptionsWrapper } = action.payload;
    const response = yield call(
      exceptionsAction,
      dataLogId,
      mappingId,
      exceptionsWrapper
    );

    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    yield put(exceptionsActionSuccess());
    const message: MessageData = {
      title: 'Success !',
      description: response.responseMessage.message,
      type: MessageStates.SUCCESS,
    };

    yield put(turnMessageOn(message));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(exceptionsActionFailure());
  }
}
