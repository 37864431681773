import { api } from 'store/api';
import { ApiResponse } from 'store/api/types';
import { Contact, HideContactData, ViesCheck, ViesInfo } from './models';

export interface GetViesInfoResponse extends ApiResponse {
  data: ViesInfo;
}
export const getViesInfo = (
  connectionId: string
): Promise<GetViesInfoResponse> =>
  api.get<GetViesInfoResponse>(`/vies/get/info/${connectionId}`);

export const activateVies = (connectionId: string): Promise<ApiResponse> =>
  api.post<ApiResponse>(`/vies/activate/${connectionId}`);

export const deactivateVies = (connectionId: string): Promise<ApiResponse> =>
  api.post<ApiResponse>(`/vies/deactivate/${connectionId}`);

export interface GetContactsResponse extends ApiResponse {
  data: { contacts: Contact[] };
}
export const getContacts = (
  connectionId: string
): Promise<GetContactsResponse> =>
  api.get<GetContactsResponse>(`/vies/get/contacts/${connectionId}`);

export interface GetContactResponse extends ApiResponse {
  data: { viesChecks: ViesCheck[]; contact: Contact };
}
export const getContact = (contactUuid: string): Promise<GetContactResponse> =>
  api.get<GetContactResponse>(`/vies/get/logs/contact/${contactUuid}`);

export interface CheckContactsResponse extends ApiResponse {
  data: { lastViesCheckDate: string; contacts: Contact[] };
}
export const checkContacts = (
  connectionId: string
): Promise<CheckContactsResponse> =>
  api.post<CheckContactsResponse>(`/vies/check/contacts/${connectionId}`);

export interface CheckContactResponse extends ApiResponse {
  data: { viesChecks: ViesCheck[]; contact: Contact };
}
export const checkContact = (
  contactUuid: string
): Promise<CheckContactResponse> =>
  api.post<CheckContactResponse>(`/vies/check/contact/${contactUuid}`);

export const refreshContacts = (connectionId: string): Promise<ApiResponse> =>
  api.post<ApiResponse>(`/vies/refresh/contacts/org/${connectionId}`);

export const refreshContact = (
  contactId: string,
  connectionId: string
): Promise<ApiResponse> =>
  api.post<ApiResponse>(
    `/vies/refresh/contacts/${contactId}/org/${connectionId}`
  );

export const hideContact = (
  hideContactData: HideContactData
): Promise<ApiResponse> => {
  return api.post<ApiResponse>(
    `/vies/contact/mute?isActive=${hideContactData.isActive}`,
    { body: { contactUuids: hideContactData.contactsToHide } }
  );
};
