import { ActivityRoles } from 'store/app/enums';
import { User } from 'store/auth/models';

interface BasicRequestData {
  connectionId: string;
  fromDate: String;
  toDate: string;
  mappingId: string;
  currency: string;
}

export interface VatGenerateRequestData extends BasicRequestData {
  dataLogId?: string;
}
export interface VatRunRequestData extends BasicRequestData {}

export interface JSONTotals {
  boxName: string;
  boxValue: string;
  boxTaxValue: string;
}

export interface JSONTotalsRecapMalta {
  isTriangularTrade: boolean;
  name: string;
  country: string;
  taxNumber: string;
  viesStatus: string;
  goodsSum: number;
  servicesSum: number;
}

export interface JSONTotalsRecapCyprus {
  country: string;
  taxNumber: string;
  viesStatus: string;
  triangularTradeSum: number;
  goodsSum: number;
  servicesSum: number;
}

export enum TransactionType {
  TRANSACTION = 'TRANSACTION',
  EXCEPTION = 'EXCEPTION',
}
export interface JSONModifiedTrn {
  uuid: string;
  modifiedNetAmount: number;
  modifiedTaxAmount: number;
  type: TransactionType;
}

export interface SaveReturnRequestData extends BasicRequestData {
  generatedDate: string;
  reportingTypeId: string;
  dataLogId?: string;
  jsonTotals: JSONTotals[];
  jsonModifiedTrns: JSONModifiedTrn[];
}

export interface ViewRunRequestData {
  connectionId: string;
  mappingId: string;
  dataLogId: string;
}

export interface VatLoadRequestData extends BasicRequestData {
  dataLogId: string;
}

export interface ActivityRoleUpdateRequest {
  activityRole: ActivityRoles;
  memberUser?: User;
  dataLogId: string;
  mappingId: string;
}
