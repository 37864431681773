import { api } from 'store/api';
import { ApiResponse } from 'store/api/types';
import moment from 'moment';
import {
  ActivityLog,
  DataStatus,
  ExceptionWrapper,
  SaveReturnRequestData,
  VatGenerateRequestData,
  VatReportData,
  VatReturnLog,
  VatRunData,
  VatRunRequestData,
  ViewRunRequestData,
} from './models';
import {
  ActivityRoleUpdateRequest,
  VatLoadRequestData,
} from './models/requestData';
import { EmailDataParametersRequest } from './models/emailDataParametersRequest';
import { ActivityRoles } from 'store/app/enums';
import { ExcelFileResponse } from './models/vatReportData';
import { ExceptionsWrapper } from './models/exceptionWrapper';

export interface GetVatLogsResponse extends ApiResponse {
  data: { logs: VatReturnLog[] };
}
export const getVatLogs = (
  connectionId: string,
  mappingId: string
): Promise<GetVatLogsResponse> =>
  api.get<GetVatLogsResponse>(
    `/auth/vat/taxreturn/logs/${connectionId}/mapping/${mappingId}`
  );

export interface GetRunDataResponse extends ApiResponse {
  data: VatRunData;
}
export const getRunData = (
  data: VatRunRequestData
): Promise<GetRunDataResponse> => {
  const url =
    '/auth/vat/run/' +
    data.connectionId +
    '/mapping/' +
    data.mappingId +
    '?fromDate=' +
    data.fromDate +
    '&toDate=' +
    data.toDate +
    '&currency=' +
    data.currency;
  return api.get<GetRunDataResponse>(url);
};

export const getViewRunData = (
  data: ViewRunRequestData
): Promise<GetRunDataResponse> => {
  const url =
    '/auth/vat/view/' +
    data.connectionId +
    '/mapping/' +
    data.mappingId +
    '/log/' +
    data.dataLogId;

  return api.get<GetRunDataResponse>(url);
};

export interface GenerateReportResponse extends ApiResponse {
  data: VatReportData;
}
export const generateReport = (
  data: VatGenerateRequestData,
  page: number
): Promise<GenerateReportResponse> => {
  const dataLogIdParam = data.dataLogId ? `&dataLogId=${data.dataLogId}` : '';
  const url =
    '/auth/vat/generate/' +
    data.connectionId +
    '/mapping/' +
    data.mappingId +
    '?fromDate=' +
    data.fromDate +
    '&toDate=' +
    data.toDate +
    '&currency=' +
    data.currency +
    '&page=' +
    page +
    '&pageSize=15000' +
    dataLogIdParam;
  return api.get<GenerateReportResponse>(url);
};

export interface LoadReportResponse extends ApiResponse {
  data: VatReportData;
}

export const loadReport = (
  data: VatLoadRequestData,
  page: number
): Promise<LoadReportResponse> => {
  const url =
    '/auth/vat/view/report/' +
    data.connectionId +
    '/mapping/' +
    data.mappingId +
    '/log/' +
    data.dataLogId +
    '?currency=' +
    data.currency +
    '&page=' +
    page +
    '&pageSize=15000';
  return api.get<LoadReportResponse>(url);
};

export interface LoadReconResponse extends ApiResponse {
  data: Map<string, string>;
}
export const loadRecon = (
  tokenId: string,
  toDate: string
): Promise<LoadReconResponse> => {
  const url = `/auth/vat/reconciliation/load/${tokenId}?toDate=${toDate}`;
  return api.get<LoadReconResponse>(url);
};

export interface SaveReturnResponse extends ApiResponse {
  data: { dataLogId: string };
}

export const saveReturn = (
  data: SaveReturnRequestData
): Promise<SaveReturnResponse> => {
  const url =
    '/auth/status/save/report/' +
    data.connectionId +
    '?mappingId=' +
    data.mappingId +
    '&fromDate=' +
    data.fromDate +
    '&toDate=' +
    data.toDate +
    '&generatedDate=' +
    data.generatedDate +
    '&currency=' +
    data.currency +
    '&reportingTypeId=' +
    data.reportingTypeId;
  return api.post<SaveReturnResponse>(url, {
    body: {
      jsonTotals: data.jsonTotals,
      jsonModifiedTrns: data.jsonModifiedTrns,
    },
  });
};

export const updateReturn = (
  data: SaveReturnRequestData
): Promise<ApiResponse> => {
  const url =
    '/auth/status/update/report/' +
    data.dataLogId +
    '?tokenId=' +
    data.connectionId +
    '&reportingTypeId=' +
    data.reportingTypeId;

  return api.post<ApiResponse>(url, {
    body: {
      jsonTotals: data.jsonTotals,
      jsonModifiedTrns: data.jsonModifiedTrns,
    },
  });
};

export const cancelReturn = (dataLogId: string): Promise<ApiResponse> => {
  const url = `/auth/status/cancel/report/${dataLogId}`;

  return api.post<ApiResponse>(url);
};

export const updateActivityRole = (
  data: ActivityRoleUpdateRequest
): Promise<ApiResponse> => {
  const removing =
    data.activityRole === ActivityRoles.REMOVE_CLIENT ||
    data.activityRole === ActivityRoles.REMOVE_REVIEWER;
  const action = removing ? 'remove' : 'assign';
  const actRole = removing
    ? data.activityRole.replace('remove-', '')
    : data.activityRole;
  const mapping = removing ? '' : `/mapping/${data.mappingId}`;
  const url =
    '/auth/activity/' +
    action +
    '/' +
    actRole +
    '/' +
    data.memberUser?.accountUuid +
    '/datalog/' +
    data.dataLogId +
    mapping;

  return api.post<ApiResponse>(url);
};

export const addNote = (
  note: string,
  dataLogId: string
): Promise<ApiResponse> => {
  const url = `/auth/activity/add/note/${dataLogId}`;

  return api.post<ApiResponse>(url, { body: { note } });
};

export interface GetActivityLogsResponse extends ApiResponse {
  data: { activities: ActivityLog[] };
}

export const getActivityLogs = (
  dataLogId: string
): Promise<GetActivityLogsResponse> => {
  const url = `/auth/activity/load/activity/datalog/${dataLogId}`;

  return api.get<GetActivityLogsResponse>(url);
};

export const awaitingApproval = (
  dataLogId: string,
  reportUrl: string
): Promise<ApiResponse> => {
  const url = `/auth/status/awaiting-approval/report/${dataLogId}`;

  return api.post<ApiResponse>(url, { body: { reportUrl } });
};

export const internallyApprove = (
  dataLogId: string,
  reportUrl: string
): Promise<ApiResponse> => {
  const url = `/auth/status/internal-approved/report/${dataLogId}`;

  return api.post<ApiResponse>(url, { body: { reportUrl } });
};

export const sendClientApproval = (
  dataLogId: string,
  emailData: EmailDataParametersRequest
): Promise<ApiResponse> => {
  const url = `/auth/status/client-approval/report/${dataLogId}`;

  return api.post<ApiResponse>(url, { body: { emailData } });
};

export const clientApprove = (
  dataLogId: string,
  reportUrl: string
): Promise<ApiResponse> => {
  const url = `/auth/status/client-approved/report/${dataLogId}`;

  return api.post<ApiResponse>(url, { body: { reportUrl } });
};

export const notApprove = (
  dataLogId: string,
  emailData: EmailDataParametersRequest
): Promise<ApiResponse> => {
  const url = `/auth/status/revert/report/${dataLogId}`;

  return api.post<ApiResponse>(url, { body: { emailData } });
};

export const submitReturn = (
  dataLogId: string,
  mappingId: string
): Promise<ApiResponse> => {
  const subDate = moment().format('DD/MM/YYYY HH:mm:ss');
  const url = `/auth/status/submit/report/${dataLogId}/mapping/${mappingId}?submittedDate=${subDate}`;

  return api.post<ApiResponse>(url);
};

export const lockReturn = (dataLogId: string): Promise<ApiResponse> => {
  const url = `/auth/status/lock/report/${dataLogId}`;

  return api.post<ApiResponse>(url);
};

export interface CheckEntityStatusResponse extends ApiResponse {
  data: { status: DataStatus };
}

export const checkEntityStatus = (
  connectionId: string
): Promise<CheckEntityStatusResponse> =>
  api.get<CheckEntityStatusResponse>(
    `/auth/organisation/get/data/download/status/${connectionId}`
  );

export interface ExportedExcelFileResponse extends ApiResponse {
  data: ExcelFileResponse;
}

export const getExcelFileForExport = (
  reportTypeId: string
): Promise<ExportedExcelFileResponse> =>
  api.get<ExportedExcelFileResponse>(`/file/report/${reportTypeId}`);

export const exceptionsAction = (
  dataLogId: string,
  mappingId: string,
  exceptionsWrapper: ExceptionsWrapper[]
): Promise<ExportedExcelFileResponse> =>
  api.get<ExportedExcelFileResponse>(
    `/auth/exceptions/action/${dataLogId}/mapping/${mappingId}`,
    {
      body: { exceptionsActionList: exceptionsWrapper },
    }
  );
