import { createAction } from 'store/utils';
import {
  AddNoteFailureAction,
  AddNoteRequestAction,
  AddNoteSuccessAction,
  AwaitingApprovalFailureAction,
  AwaitingApprovalRequestAction,
  AwaitingApprovalSuccessAction,
  CancelReturnAction,
  CancelReturnFailureAction,
  CancelReturnSuccessAction,
  ChangeActivityRoleAction,
  ChangeStatusAfterUpdateAction,
  CheckEntityStatusFailureAction,
  CheckEntityStatusRequestAction,
  CheckEntityStatusSuccessAction,
  ClientApproveFailureAction,
  ClientApproveRequestAction,
  ClientApproveSuccessAction,
  ExceptionsActionFailureAction,
  ExceptionsActionRequestAction,
  ExceptionsActionSuccessAction,
  FetchActivityLogsFailureAction,
  FetchActivityLogsRequestAction,
  FetchActivityLogsSuccessAction,
  FetchExcelFileFailureAction,
  FetchExcelFileRequestAction,
  FetchExcelFileSuccessAction,
  FetchRunDataFailureAction,
  FetchRunDataRequestAction,
  FetchRunDataSuccessAction,
  FetchVatLogsFailureAction,
  FetchVatLogsRequestAction,
  FetchVatLogsSuccessAction,
  FetchViewRunDataRequestAction,
  GenerateReportFailureAction,
  GenerateReportRequestAction,
  GenerateReportSuccessAction,
  InternallyApproveFailureAction,
  InternallyApproveRequestAction,
  InternallyApproveSuccessAction,
  LoadReconFailureAction,
  LoadReconRequestAction,
  LoadReconSuccessAction,
  LoadReportRequestAction,
  LockReturnFailureAction,
  LockReturnRequestAction,
  LockReturnSuccessAction,
  NotApproveFailureAction,
  NotApproveRequestAction,
  NotApproveSuccessAction,
  ResetVatDataAction,
  SaveReturnAction,
  SaveReturnFailureAction,
  SaveReturnSuccessAction,
  SendClientApprovalFailureAction,
  SendClientApprovalRequestAction,
  SendClientApprovalSuccessAction,
  SubmitReturnFailureAction,
  SubmitReturnRequestAction,
  SubmitReturnSuccessAction,
  UpdateActivityRoleFailureAction,
  UpdateActivityRoleRequestAction,
  UpdateActivityRoleSuccessAction,
} from './types';
import {
  ADD_NOTE_FAILURE,
  ADD_NOTE_REQUEST,
  ADD_NOTE_SUCCESS,
  AWAITING_APPROVAL_FAILURE,
  AWAITING_APPROVAL_REQUEST,
  AWAITING_APPROVAL_SUCCESS,
  CANCEL_RETURN_FAILURE,
  CANCEL_RETURN_REQUEST,
  CANCEL_RETURN_SUCCESS,
  CHANGE_ACTIVITY_ROLE,
  CHANGE_STATUS_AFTER_UPDATE,
  CHECK_ENTITY_STATUS_FAILURE,
  CHECK_ENTITY_STATUS_REQUEST,
  CHECK_ENTITY_STATUS_SUCCESS,
  CLIENT_APPROVE_FAILURE,
  CLIENT_APPROVE_REQUEST,
  CLIENT_APPROVE_SUCCESS,
  EXCEPTIONS_ACTION_FAILURE,
  EXCEPTIONS_ACTION_REQUEST,
  EXCEPTIONS_ACTION_SUCCESS,
  FETCH_ACTIVITY_LOGS_FAILURE,
  FETCH_ACTIVITY_LOGS_REQUEST,
  FETCH_ACTIVITY_LOGS_SUCCESS,
  FETCH_EXCEL_FILE_FAILURE,
  FETCH_EXCEL_FILE_REQUEST,
  FETCH_EXCEL_FILE_SUCCESS,
  FETCH_RUN_DATA_FAILURE,
  FETCH_RUN_DATA_REQUEST,
  FETCH_RUN_DATA_SUCCESS,
  FETCH_VAT_LOGS_FAILURE,
  FETCH_VAT_LOGS_REQUEST,
  FETCH_VAT_LOGS_SUCCESS,
  FETCH_VIEW_RUN_DATA_REQUEST,
  GENERATE_REPORT_FAILURE,
  GENERATE_REPORT_REQUEST,
  GENERATE_REPORT_SUCCESS,
  INTERNALLY_APPROVE_FAILURE,
  INTERNALLY_APPROVE_REQUEST,
  INTERNALLY_APPROVE_SUCCESS,
  LOAD_RECON_FAILURE,
  LOAD_RECON_REQUEST,
  LOAD_RECON_SUCCESS,
  LOAD_REPORT_REQUEST,
  LOCK_RETURN_FAILURE,
  LOCK_RETURN_REQUEST,
  LOCK_RETURN_SUCCESS,
  NOT_APPROVE_FAILURE,
  NOT_APPROVE_REQUEST,
  NOT_APPROVE_SUCCESS,
  RESET_VAT_DATA,
  SAVE_RETURN_FAILURE,
  SAVE_RETURN_REQUEST,
  SAVE_RETURN_SUCCESS,
  SEND_CLIENT_APPROVAL_FAILURE,
  SEND_CLIENT_APPROVAL_REQUEST,
  SEND_CLIENT_APPROVAL_SUCCESS,
  SUBMIT_RETURN_FAILURE,
  SUBMIT_RETURN_REQUEST,
  SUBMIT_RETURN_SUCCESS,
  UPDATE_ACTIVITY_ROLE_FAILURE,
  UPDATE_ACTIVITY_ROLE_REQUEST,
  UPDATE_ACTIVITY_ROLE_SUCCESS,
} from './constants';
import {
  ActivityLog,
  ExceptionWrapper,
  SaveReturnRequestData,
  VatGenerateRequestData,
  VatReportData,
  VatReturnLog,
  VatRunRequestData,
  ViewRunRequestData,
} from './models';
import { VatRunData } from './models/vatRunData';
import {
  ActivityRoleUpdateRequest,
  VatLoadRequestData,
} from './models/requestData';
import { ActivityRoles, DataLogStatus } from 'store/app/enums';
import { EmailDataParametersRequest } from './models/emailDataParametersRequest';
import { User } from 'store/auth/models';
import { ExcelFileResponse } from './models/vatReportData';
import { ExceptionsWrapper } from './models/exceptionWrapper';

// Fetch Vat Logs
export const fetchVatLogsRequest = (
  connectionId: string,
  mappingId: string[]
): FetchVatLogsRequestAction =>
  createAction(FETCH_VAT_LOGS_REQUEST, { connectionId, mappingId });

export const fetchVatLogsSuccess = (
  vatReturnLogs: VatReturnLog[]
): FetchVatLogsSuccessAction =>
  createAction(FETCH_VAT_LOGS_SUCCESS, {
    vatReturnLogs,
  });

export const fetchVatLogsFailure = (): FetchVatLogsFailureAction =>
  createAction(FETCH_VAT_LOGS_FAILURE);

// Fetch Run Data
export const fetchRunDataRequest = (
  vatRunRequestData: VatRunRequestData
): FetchRunDataRequestAction =>
  createAction(FETCH_RUN_DATA_REQUEST, { vatRunRequestData });

export const fetchViewRunDataRequest = (
  viewRunRequestData: ViewRunRequestData
): FetchViewRunDataRequestAction =>
  createAction(FETCH_VIEW_RUN_DATA_REQUEST, { viewRunRequestData });

export const fetchRunDataSuccess = (
  vatRunData: VatRunData
): FetchRunDataSuccessAction =>
  createAction(FETCH_RUN_DATA_SUCCESS, {
    vatRunData,
  });

export const fetchRunDataFailure = (): FetchRunDataFailureAction =>
  createAction(FETCH_RUN_DATA_FAILURE);

// Generate Report
export const generateReportRequest = (
  vatGenerateRequestData: VatGenerateRequestData,
  totalTransactions: number
): GenerateReportRequestAction =>
  createAction(GENERATE_REPORT_REQUEST, {
    vatGenerateRequestData,
    totalTransactions,
  });

export const loadReportRequest = (
  vatLoadRequestData: VatLoadRequestData
): LoadReportRequestAction =>
  createAction(LOAD_REPORT_REQUEST, { vatLoadRequestData });

export const generateReportSuccess = (
  vatReportData: VatReportData,
  lastPage: boolean
): GenerateReportSuccessAction =>
  createAction(GENERATE_REPORT_SUCCESS, {
    vatReportData,
    lastPage,
  });

export const generateReportFailure = (): GenerateReportFailureAction =>
  createAction(GENERATE_REPORT_FAILURE);

// LoadReconciliation
export const loadReconRequest = (
  tokenId: string,
  toDate: string
): LoadReconRequestAction =>
  createAction(LOAD_RECON_REQUEST, { tokenId, toDate });

export const loadReconSuccess = (
  reconMap: Map<string, string>
): LoadReconSuccessAction =>
  createAction(LOAD_RECON_SUCCESS, {
    reconMap,
  });

export const loadReconFailure = (): LoadReconFailureAction =>
  createAction(LOAD_RECON_FAILURE);

// Save Return
export const saveReturnRequest = (
  saveReturnRequestData: SaveReturnRequestData
): SaveReturnAction =>
  createAction(SAVE_RETURN_REQUEST, { saveReturnRequestData });

export const saveReturnSuccess = (
  dataLogId: string,
  users: User[],
  currentUser: User,
  generatedDate: string
): SaveReturnSuccessAction =>
  createAction(SAVE_RETURN_SUCCESS, {
    dataLogId,
    users,
    currentUser,
    generatedDate,
  });

export const saveReturnFailure = (): SaveReturnFailureAction =>
  createAction(SAVE_RETURN_FAILURE);

// Cancel Return
export const cancelReturnRequest = (
  dataLogId: string,
  connectionId: string,
  mappingId: string
): CancelReturnAction =>
  createAction(CANCEL_RETURN_REQUEST, { dataLogId, connectionId, mappingId });

export const cancelReturnSuccess = (): CancelReturnSuccessAction =>
  createAction(CANCEL_RETURN_SUCCESS);

export const cancelReturnFailure = (): CancelReturnFailureAction =>
  createAction(CANCEL_RETURN_FAILURE);

// Update Activity Role
export const updateActivityRoleRequest = (
  activityRoleUpdateRequest: ActivityRoleUpdateRequest
): UpdateActivityRoleRequestAction =>
  createAction(UPDATE_ACTIVITY_ROLE_REQUEST, { activityRoleUpdateRequest });

export const updateActivityRoleSuccess = (): UpdateActivityRoleSuccessAction =>
  createAction(UPDATE_ACTIVITY_ROLE_SUCCESS);

export const updateActivityRoleFailure = (): UpdateActivityRoleFailureAction =>
  createAction(UPDATE_ACTIVITY_ROLE_FAILURE);

// Add Note
export const addNoteRequest = (
  note: string,
  dataLogId: string
): AddNoteRequestAction => createAction(ADD_NOTE_REQUEST, { note, dataLogId });

export const addNoteSuccess = (): AddNoteSuccessAction =>
  createAction(ADD_NOTE_SUCCESS);

export const addNoteFailure = (): AddNoteFailureAction =>
  createAction(ADD_NOTE_FAILURE);

// Fetch Activity Logs
export const fetchActivityLogsRequest = (
  dataLogId: string
): FetchActivityLogsRequestAction =>
  createAction(FETCH_ACTIVITY_LOGS_REQUEST, { dataLogId });

export const fetchActivitySuccess = (
  activityLogs: ActivityLog[]
): FetchActivityLogsSuccessAction =>
  createAction(FETCH_ACTIVITY_LOGS_SUCCESS, { activityLogs });

export const fetchActivityLogsFailure = (): FetchActivityLogsFailureAction =>
  createAction(FETCH_ACTIVITY_LOGS_FAILURE);

//Change Status
export const changeStatusAfterUpdate = (
  status: DataLogStatus
): ChangeStatusAfterUpdateAction =>
  createAction(CHANGE_STATUS_AFTER_UPDATE, { status });

// Awaiting Approval
export const awaitingApprovalRequest = (
  dataLogId: string,
  reportUrl: string,
  mappingId: string
): AwaitingApprovalRequestAction =>
  createAction(AWAITING_APPROVAL_REQUEST, { dataLogId, reportUrl, mappingId });

export const awaitingApprovalSuccess = (): AwaitingApprovalSuccessAction =>
  createAction(AWAITING_APPROVAL_SUCCESS);

export const awaitingApprovalFailure = (): AwaitingApprovalFailureAction =>
  createAction(AWAITING_APPROVAL_FAILURE);

//Change Activity Role
export const changeActivityRole = (
  activityRole: ActivityRoles,
  userId: string
): ChangeActivityRoleAction =>
  createAction(CHANGE_ACTIVITY_ROLE, {
    activityRole,
    userId,
  });

// Internally Approve
export const internallyApproveRequest = (
  dataLogId: string,
  reportUrl: string,
  mappingId: string
): InternallyApproveRequestAction =>
  createAction(INTERNALLY_APPROVE_REQUEST, { dataLogId, reportUrl, mappingId });

export const internallyApproveSuccess = (): InternallyApproveSuccessAction =>
  createAction(INTERNALLY_APPROVE_SUCCESS);

export const internallyApproveFailure = (): InternallyApproveFailureAction =>
  createAction(INTERNALLY_APPROVE_FAILURE);

// Send for client approval
export const sendClientApprovalRequest = (
  dataLogId: string,
  emailData: EmailDataParametersRequest,
  mappingId: string
): SendClientApprovalRequestAction =>
  createAction(SEND_CLIENT_APPROVAL_REQUEST, {
    dataLogId,
    emailData,
    mappingId,
  });

export const sendClientApprovalSuccess = (): SendClientApprovalSuccessAction =>
  createAction(SEND_CLIENT_APPROVAL_SUCCESS);

export const sendClientApprovalFailure = (): SendClientApprovalFailureAction =>
  createAction(SEND_CLIENT_APPROVAL_FAILURE);

// Client approve
export const clientApproveRequest = (
  dataLogId: string,
  reportUrl: string,
  mappingId: string
): ClientApproveRequestAction =>
  createAction(CLIENT_APPROVE_REQUEST, { dataLogId, reportUrl, mappingId });

export const clientApproveSuccess = (): ClientApproveSuccessAction =>
  createAction(CLIENT_APPROVE_SUCCESS);

export const clientApproveFailure = (): ClientApproveFailureAction =>
  createAction(CLIENT_APPROVE_FAILURE);

// Not approve
export const notApproveRequest = (
  dataLogId: string,
  emailData: EmailDataParametersRequest,
  mappingId: string
): NotApproveRequestAction =>
  createAction(NOT_APPROVE_REQUEST, { dataLogId, emailData, mappingId });

export const notApproveSuccess = (): NotApproveSuccessAction =>
  createAction(NOT_APPROVE_SUCCESS);

export const notApproveFailure = (): NotApproveFailureAction =>
  createAction(NOT_APPROVE_FAILURE);

// Submit Return
export const submitReturnRequest = (
  dataLogId: string,
  mappingId: string
): SubmitReturnRequestAction =>
  createAction(SUBMIT_RETURN_REQUEST, { dataLogId, mappingId });

export const submitReturnSuccess = (): SubmitReturnSuccessAction =>
  createAction(SUBMIT_RETURN_SUCCESS);

export const submitReturnFailure = (): SubmitReturnFailureAction =>
  createAction(SUBMIT_RETURN_FAILURE);

// Lock Return
export const lockReturnRequest = (
  dataLogId: string,
  mappingId: string
): LockReturnRequestAction =>
  createAction(LOCK_RETURN_REQUEST, { dataLogId, mappingId });

export const lockReturnSuccess = (): LockReturnSuccessAction =>
  createAction(LOCK_RETURN_SUCCESS);

export const lockReturnFailure = (): LockReturnFailureAction =>
  createAction(LOCK_RETURN_FAILURE);

// RESET
export const resetVatData = (): ResetVatDataAction =>
  createAction(RESET_VAT_DATA);

// Check Entity status
export const checkEntityStatusRequest = (
  connectionId: string
): CheckEntityStatusRequestAction =>
  createAction(CHECK_ENTITY_STATUS_REQUEST, { connectionId });

export const checkEntityStatusSuccess = (): CheckEntityStatusSuccessAction =>
  createAction(CHECK_ENTITY_STATUS_SUCCESS);

export const checkEntityStatusFailure = (): CheckEntityStatusFailureAction =>
  createAction(CHECK_ENTITY_STATUS_FAILURE);

// Fetch excel file

export const fetchExcelFileRequest = (
  reportTypeId: string
): FetchExcelFileRequestAction =>
  createAction(FETCH_EXCEL_FILE_REQUEST, { reportTypeId });

export const fetchExcelFileSuccess = (
  data: ExcelFileResponse
): FetchExcelFileSuccessAction =>
  createAction(FETCH_EXCEL_FILE_SUCCESS, {
    data,
  });

export const fetchExcelFileFailure = (): FetchExcelFileFailureAction =>
  createAction(FETCH_EXCEL_FILE_FAILURE);

// Exceptions Action

export const exceptionsActionRequest = (
  dataLogId: string,
  mappingId: string,
  exceptionsWrapper: ExceptionsWrapper[]
): ExceptionsActionRequestAction =>
  createAction(EXCEPTIONS_ACTION_REQUEST, {
    dataLogId,
    mappingId,
    exceptionsWrapper,
  });

export const exceptionsActionSuccess = (): ExceptionsActionSuccessAction =>
  createAction(EXCEPTIONS_ACTION_SUCCESS);

export const exceptionsActionFailure = (): ExceptionsActionFailureAction =>
  createAction(EXCEPTIONS_ACTION_FAILURE);
