import { Popover, Space, Spin, Switch, TableProps, Typography } from 'antd';
import {
  ContextButton,
  ExportButton,
  IconButton,
  IconContextButton,
  ScopeButton,
} from 'components/Custom/buttons';
import moment from 'moment';
import { DashboardContainer, GrayItalicSpan } from 'components/Custom/spans';
import { ScopeTable } from 'components/Custom/table';
import React, { useEffect, useState } from 'react';
import { ReactComponent as UserIcon } from 'assets/images/icons/userIcon.svg';
import { ReactComponent as StarIcon } from 'assets/images/icons/starIcon.svg';
import { ReactComponent as WarningIcon } from 'assets/images/icons/warningIcon.svg';
import { ReactComponent as ExclamationIcon } from 'assets/images/icons/exclamationIcon.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/images/icons/checkCircleIcon.svg';
import { ReactComponent as RefreshIcon } from 'assets/images/icons/refreshIcon.svg';
import { ReactComponent as EllipseIcon } from 'assets/images/icons/ellipsesIcon.svg';
import { ReactComponent as HistoryIcon } from 'assets/images/icons/historyIcon.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/icons/downloadIcon.svg';
import { ReactComponent as EyeIcon } from 'assets/images/icons/eyeIcon.svg';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  DatabaseOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { ContactType } from 'store/app/enums';
import { Contact, HideContactData, ViesInfo } from 'store/vat-checker/models';
import { VIESContactStatistic, ViesStatus } from 'store/app/enums';
import { getVIESProviderLink } from 'utils/get-provider-link';
import { StatusGetter } from 'utils/status-getter';
import { Link } from 'react-router-dom';
import { Organisation } from 'store/organisation/models';
import { Preference, PreferenceActions } from 'utils/preferences';
import { FILTER_BOX_SELECTED } from 'pages/vies/customers-suppliers';
import { ReactComponent as IconExport } from 'assets/images/icons/iconExport.svg';
import { CSVLink } from 'react-csv';

interface CustomersAndSuppliersProps {
  loading: boolean;
  contacts?: Contact[];
  organisation?: Organisation;
  openDownloadCertificateModal: (record: Contact, invoice?: string) => void;
  openConfirmWindow: () => void;
  checkContact: (contactId: string) => void;
  getHistoryLogs: (record: Contact) => void;
  viesInfo?: ViesInfo;
  onRefreshContacts: (connectionId: string) => void;
  onRefreshContact: (contactId: string, connectionId: string) => void;
  handlePreferences: (
    action: PreferenceActions,
    preference: Preference
  ) => void;
  hideContact: (hideContactData: HideContactData) => void;
  dataToExport?: Contact[];
}

const { Text } = Typography;
export const PAGE_SIZE_VIES = 'page-size-vies';

export const CustomersAndSuppliers: React.FC<CustomersAndSuppliersProps> = ({
  loading,
  contacts,
  organisation,
  openDownloadCertificateModal,
  openConfirmWindow,
  checkContact,
  getHistoryLogs,
  viesInfo,
  onRefreshContacts,
  onRefreshContact,
  handlePreferences,
  hideContact,
  dataToExport,
}) => {
  const pageSizeSaved = handlePreferences(PreferenceActions.GET, {
    name: PAGE_SIZE_VIES,
  });
  const { t } = useTranslation();
  const filterBoxSelected = handlePreferences(PreferenceActions.GET, {
    name: FILTER_BOX_SELECTED,
  });
  const [selectedContaniers, setSelectedContaniers] = useState<string[]>([]);
  const [showHiddenContacts, setShowHiddenContacts] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState(contacts);
  const [statusFilter, setStatusFilter] = useState('');

  useEffect(() => {
    //setDataSource(contacts);
    //filterBoxSelected && filterContacts(filterBoxSelected, true);
    filterContacts(filterBoxSelected, true);
  }, [JSON.stringify(contacts)]);

  useEffect(() => {
    if (selectedContaniers.length == 0) {
      setDataSource(contacts);
    }
  }, [selectedContaniers]);

  useEffect(() => {
    const contactsToShow = contacts?.filter(
      (contact) => contact.isVisible == !showHiddenContacts
    );
    setDataSource(contactsToShow);
  }, [showHiddenContacts]);

  const filterContacts = (key, useOriginal = false) => {
    const selectedCards = [...selectedContaniers];
    selectedCards.push(key);
    setSelectedContaniers(selectedCards);
    //@ts-ignore
    const contactDataSource = useOriginal
      ? contacts && [...contacts]
      : dataSource && [...dataSource];
    switch (key) {
      case VIESContactStatistic.Customers:
        setDataSource(
          contactDataSource?.filter(
            (item) =>
              item.contactType &&
              (ContactType[item.contactType] == ContactType.CUSTOMER ||
                ContactType[item.contactType] == ContactType.BOTH) &&
              item.isVisible == !showHiddenContacts
          )
        );
        break;
      case VIESContactStatistic.Suppliers:
        setDataSource(
          contactDataSource?.filter(
            (item) =>
              item.contactType &&
              (ContactType[item.contactType] == ContactType.SUPPLIER ||
                ContactType[item.contactType] == ContactType.BOTH) &&
              item.isVisible == !showHiddenContacts
          )
        );
        break;
      case VIESContactStatistic.ContactsErrors:
        setDataSource(
          contactDataSource?.filter(
            (item) =>
              (item.viesStatus == ViesStatus.ERROR ||
                item.viesStatus == ViesStatus.INVALID) &&
              item.isVisible == !showHiddenContacts
          )
        );
        setStatusFilter(VIESContactStatistic.ContactsErrors);
        break;
      case VIESContactStatistic.StatusMissing:
        setDataSource(
          contactDataSource?.filter(
            (item) =>
              item.viesStatus == ViesStatus.MISSING &&
              item.isVisible == !showHiddenContacts
          )
        );
        setStatusFilter(VIESContactStatistic.StatusMissing);
        break;
      default:
        setDataSource(
          contactDataSource?.filter(
            (item) => item.isVisible == !showHiddenContacts
          )
        );
        break;
    }
  };

  const onHideContactClick = (isActive: boolean, contactUuid: string) => {
    const hideContactData: HideContactData = {
      isActive,
      contactsToHide: [contactUuid],
    };
    hideContact(hideContactData);

    setTimeout(() => {
      setSelectedRowKeys([]);
    }, 1000);
  };

  const onHideContactsClick = (isActive: boolean) => {
    const hideContactData: HideContactData = {
      isActive, //@ts-ignore
      contactsToHide: selectedRowKeys,
    };
    hideContact(hideContactData);

    setTimeout(() => {
      setSelectedRowKeys([]);
    }, 1000);
  };

  const filterContactsPerType = (addedKey, removedKey) => {
    const selectedCards = [...selectedContaniers];
    selectedCards.unshift(addedKey);
    const newSelectedCards = selectedCards.filter((card) => card != removedKey);
    let filteredData: Contact[] | undefined = [];
    newSelectedCards.forEach((key, index) => {
      if (index == 0) {
        if (addedKey.includes(ContactType.SUPPLIER.toLowerCase())) {
          filteredData =
            //@ts-ignore
            contacts?.filter(
              (contact) =>
                contact.contactType &&
                (ContactType[contact.contactType] == ContactType.SUPPLIER ||
                  ContactType[contact.contactType] == ContactType.BOTH)
            );
          //);
        } else if (addedKey.includes(ContactType.CUSTOMER.toLowerCase())) {
          filteredData =
            //@ts-ignore
            contacts?.filter(
              (contact) =>
                contact.contactType &&
                (ContactType[contact.contactType] == ContactType.CUSTOMER ||
                  ContactType[contact.contactType] == ContactType.BOTH)
            );
        }
      } else {
        if (key == VIESContactStatistic.ContactsErrors) {
          filteredData = filteredData?.filter(
            (item) =>
              item.viesStatus == ViesStatus.ERROR ||
              item.viesStatus == ViesStatus.INVALID
          );
          setStatusFilter(VIESContactStatistic.ContactsErrors);
        } else if (key == VIESContactStatistic.StatusMissing) {
          filteredData = filteredData?.filter(
            (item) => item.viesStatus == ViesStatus.MISSING
          );
          setStatusFilter(VIESContactStatistic.StatusMissing);
        }
      }
    });
    setDataSource(filteredData);
    setSelectedContaniers(newSelectedCards);
  };

  const removeSelection = (key) => {
    const selectedCards = [...selectedContaniers];
    const newSelectedCards = selectedCards.filter((card) => card != key);
    newSelectedCards.forEach((key) => filterContacts(key, true));
    setSelectedContaniers(newSelectedCards);

    if (
      key == VIESContactStatistic.StatusMissing ||
      key == VIESContactStatistic.ContactsErrors
    ) {
      setStatusFilter('');
    }
  };

  const calcContactsType = (type) => {
    return dataSource?.filter(
      (item) =>
        item.contactType &&
        (ContactType[item.contactType] == ContactType[type] ||
          ContactType[item.contactType] == ContactType.BOTH)
    )?.length;
  };

  const calcContactsStatus = (status) => {
    return dataSource?.filter((item) => item.viesStatus == status)?.length;
  };

  const calcErrorsStatus = () => {
    return dataSource?.filter(
      (item) =>
        item.viesStatus == ViesStatus.ERROR ||
        item.viesStatus == ViesStatus.INVALID
    )?.length;
  };

  const rowSelection: TableProps<Contact>['rowSelection'] = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: Contact[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: Contact) => ({
      name: record.name,
    }),
  };

  const renderSortIcon = ({ sortOrder }) => {
    if (sortOrder == 'ascend') {
      return <ArrowDownOutlined style={{ color: '#879494' }} />;
    } else {
      return <ArrowUpOutlined style={{ color: '#879494' }} />;
    }
  };

  const getHeaderForExport = (dataToExport) => {
    const obj = dataToExport?.length > 0 ? dataToExport[0] : null;
    let headerArr: any = [];
    if (obj) {
      const headers = Object.keys(obj);
      headerArr = headers.map((key) => ({
        label: key.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
        key: key,
      }));
    }

    return headerArr;
  };

  const prepareDataToExport = (dataToExport: Contact[]) => {
    if (Object.keys(ViesStatus).includes(dataToExport[0].viesStatus)) {
      const date = dataToExport.map((contact) => {
        contact.viesStatus = ViesStatus[contact.viesStatus];
        contact.lastValidCheck = contact.lastValidCheck?.viesCheckedDate;
        return contact;
      });
      return date;
    } else {
      return dataToExport;
    }
  };

  const renderCustomizeSection = () => {
    return (
      <Space>
        {/*         <GrayItalicSpan color="#879494">
          {t('vat-checker.show-hidden')}
        </GrayItalicSpan>
        <Switch
          title={t('vat-checker.show-hidden')}
          className={showHiddenContacts ? 'switch-checked' : ''}
          checked={showHiddenContacts}
          onChange={(
            checked: boolean,
            _event: React.MouseEvent<HTMLButtonElement>
          ) => {
            setShowHiddenContacts(checked);
          }}
        /> */}
        <GrayItalicSpan color="#879494">
          {viesInfo?.lastViesCheckDate &&
            `Last check: ${moment(viesInfo?.lastViesCheckDate).fromNow()}`}
        </GrayItalicSpan>
        <IconButton //@ts-ignore
          width="107px"
          height="30px"
          fontSize="12px"
          lineheight="12px"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '4px 8px',
          }}
          onClick={() => openConfirmWindow()}
          icon={<CheckCircleIcon width={'16px'} height={'16px'} />}
          type="default"
          loading={loading}
          disabled={selectedRowKeys.length > 0}
        >
          {t('vat-checker.check-all-vat')}
        </IconButton>
        <IconButton
          loading={loading} //@ts-ignore
          width="225px"
          height="30px"
          fontSize="12px"
          lineheight="12px"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '4px 8px',
          }}
          onClick={() => {
            viesInfo && onRefreshContacts(viesInfo?.connectionId);
          }}
          icon={
            <RefreshIcon
              width={'18px'}
              height={'18px'}
              style={{
                filter:
                  'brightness(0) saturate(100%) invert(62%) sepia(19%) saturate(146%) hue-rotate(131deg) brightness(90%) contrast(88%)',
              }}
            />
          }
          type="default"
          disabled={selectedRowKeys.length > 0}
        >
          {t('vat-checker.refresh-customers-suppliers')}
        </IconButton>
        <IconButton
          loading={loading} //@ts-ignore
          width="225px"
          height="30px"
          fontSize="12px"
          lineheight="12px"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '4px 8px',
          }}
          icon={<IconExport />}
        >
          <CSVLink
            className="link-without-underline"
            filename={`${organisation?.friendlyName} - contacts.csv`}
            data={contacts?.length > 0 ? prepareDataToExport(contacts) : []}
            headers={getHeaderForExport(contacts?.length > 0 ? contacts : [])}
          >
            <span>Export list</span>
          </CSVLink>
        </IconButton>
        <IconButton //@ts-ignore
          width="107px"
          height="30px"
          fontSize="12px"
          lineheight="12px"
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '4px 8px',
          }}
          disabled={selectedRowKeys.length == 0}
          onClick={() => onHideContactsClick(showHiddenContacts)}
          icon={
            showHiddenContacts ? (
              <EyeOutlined style={{ fontSize: '16px', color: '#879494' }} />
            ) : (
              <EyeInvisibleOutlined
                style={{ fontSize: '16px', color: '#879494' }}
              />
            )
          }
          type="default"
          loading={loading}
        >
          {showHiddenContacts ? 'Unhide selected' : 'Hide selected'}
        </IconButton>
      </Space>
    );
  };

  const renderAppendedFilter = () => {
    return (
      <Space>
        <GrayItalicSpan color="#879494">
          {t('vat-checker.show-hidden')}
        </GrayItalicSpan>
        <Switch
          title={t('vat-checker.show-hidden')}
          className={showHiddenContacts ? 'switch-checked' : ''}
          checked={showHiddenContacts}
          onChange={(
            checked: boolean,
            _event: React.MouseEvent<HTMLButtonElement>
          ) => {
            setShowHiddenContacts(checked);
          }}
        />
      </Space>
    );
  };

  const clearAppendedFilter = () => {
    setShowHiddenContacts(false);
  };

  const renderContact = (content: string, record: Contact) => {
    const to = `/vat-checker/contact-details/connection/${organisation?.connectionId}/contact/${record.contactUuid}`;
    return (
      <Space size={'small'} style={{ minWidth: '140px' }}>
        <a
          href={getVIESProviderLink(
            record.providerName?.toLowerCase(),
            record.providerName?.toLowerCase() == 'xero'
              ? organisation?.shortCode
              : record?.contactType &&
                  ContactType[record?.contactType].toLowerCase(),
            record.providerContactId
          )}
          target="_blank"
          rel="noreferrer"
          style={{ height: '32px', display: 'block' }}
        >
          <img
            src={
              record?.providerName
                ? require(`shared/assets/images/${record.providerName.toLowerCase()}_small.png`)
                    .default
                : ''
            }
            width={25}
            style={{ paddingTop: '4px' }}
          />
        </a>
        <Link to={to}>
          <Text
            className="underline-link"
            style={{ minWidth: 150, maxWidth: 150 }} //@ts-ignore
            ellipsis={{ rows: 1, tooltip: true }}
          >
            {content}
          </Text>
        </Link>
      </Space>
    );
  };

  const renderContextMenu = (record: Contact) => {
    const to = `/vat-checker/contact-details/connection/${organisation?.connectionId}/contact/${record.contactUuid}`;
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          <IconContextButton
            type="text"
            icon={
              <CheckCircleIcon
                width={'16px'}
                height={'16px'}
                style={{
                  filter:
                    'brightness(0) saturate(100%) invert(13%) sepia(44%) saturate(720%) hue-rotate(131deg) brightness(95%) contrast(100%)',
                }}
                loading={loading}
              />
            }
            onClick={() => {
              checkContact(record.contactUuid);
            }}
          >
            {t('vat-checker.check-vat')}
          </IconContextButton>
          <IconContextButton
            loading={loading}
            type="text"
            icon={<RefreshIcon width={'18px'} height={'18px'} />}
            onClick={() => {
              viesInfo &&
                onRefreshContact(record.contactUuid, viesInfo?.connectionId);
            }}
          >
            {t('vat-checker.refresh-contact-data')}
          </IconContextButton>
          <IconContextButton
            type="text"
            icon={<HistoryIcon width={'18px'} height={'18px'} />}
            onClick={() => {
              getHistoryLogs(record);
            }}
          >
            {t('vat-checker.history-log')}
          </IconContextButton>
          {record.lastValidCheck && (
            <IconContextButton
              type="text"
              icon={<DownloadIcon width={'18px'} height={'18px'} />}
              onClick={() => {
                const lastViesCheck = record.lastValidCheck?.viesCheckedDate;
                const contactToSend = { ...record, lastViesCheck } as Contact;

                openDownloadCertificateModal(
                  contactToSend,
                  record.lastValidCheck?.invoiceNumber
                    ? record.lastValidCheck?.invoiceNumber
                    : ''
                );
              }}
            >
              {t('vat-checker.download-last-certificate')}
            </IconContextButton>
          )}
          <IconContextButton
            type="text"
            icon={
              <DatabaseOutlined
                style={{ fontSize: '16px', width: '18px', height: '20px' }}
              />
            }
          >
            <Link style={{ textDecoration: 'none' }} to={to}>
              {t('vat-checker.view-contact')}
            </Link>
          </IconContextButton>
          {record.isVisible ? (
            <IconContextButton
              type="text"
              icon={
                <EyeInvisibleOutlined
                  style={{ fontSize: '16px', width: '18px', height: '20px' }}
                />
              }
              onClick={() => {
                onHideContactClick(!record.isVisible, record.contactUuid);
              }}
            >
              {t('vat-checker.hide-contact')}
            </IconContextButton>
          ) : (
            <IconContextButton
              type="text"
              icon={<EyeIcon width={'18px'} height={'22px'} />}
              onClick={() => {
                onHideContactClick(!record.isVisible, record.contactUuid);
              }}
            >
              {t('vat-checker.show-contact')}
            </IconContextButton>
          )}
        </Space>
      </div>
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      //ellipsis: true,
      render: renderContact /* (content: string) => (
        <Text
          style={{ minWidth: 400, maxWidth: 500 }}
          //ellipsis={{ rows: 1, tooltip: true }}
        >
          {record.providerName}-{content}
        </Text>
      ) */,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortIcon: renderSortIcon,
    },
    {
      title: 'VAT No',
      dataIndex: 'taxNumber',
      key: 'taxNumber',
      render: (_, record: Contact) => (
        <>
          {record.taxNumber && record.taxNumber != 'null'
            ? record.taxNumber
            : '-'}
        </>
      ),
      sorter: (a, b) => a.taxNumber?.localeCompare(b.taxNumber),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      sorter: (a, b) => a.country?.localeCompare(b.country),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Type',
      key: 'contactType',
      render: (_, record: Contact) => (
        <>{record.contactType ? ContactType[record.contactType] : '-'}</>
      ),
    },
    {
      title: 'Last Certificate',
      render: (_content: string, record: Contact) => {
        if (!record.lastValidCheck) return <span>-</span>;
        else
          return (
            <Space>
              <span>
                {record &&
                  moment(record.lastValidCheck.viesCheckedDate).format(
                    'DD/MM/YYYY'
                  )}
              </span>
              <IconButton
                onClick={() => {
                  const lastViesCheck = record.lastValidCheck?.viesCheckedDate;
                  const contactToSend = { ...record, lastViesCheck } as Contact;

                  openDownloadCertificateModal(
                    contactToSend,
                    record.lastValidCheck?.invoiceNumber
                      ? record.lastValidCheck?.invoiceNumber
                      : ''
                  );
                }}
                icon={
                  <DownloadIcon
                    width={'18px'}
                    height={'18px'}
                    style={{
                      filter:
                        'brightness(0) saturate(100%) invert(62%) sepia(19%) saturate(146%) hue-rotate(131deg) brightness(90%) contrast(88%)',
                    }}
                  />
                }
                type="default"
              ></IconButton>
            </Space>
          );
      },
    },
    {
      title: 'Last Check',
      dataIndex: 'lastViesCheck',
      key: 'lastViesCheck',
      render: (_content: string, record: Contact) => {
        return (
          <Space>
            <div style={{ width: 130 }}>
              {record &&
                moment(record.lastViesCheck).format('DD/MM/YYYY hh:mm:ss')}
            </div>
            <IconButton
              disabled={record.viesStatus == 'EMPTY'}
              onClick={() => checkContact(record.contactUuid)}
              icon={
                <CheckCircleIcon
                  width={'16px'}
                  height={'16px'}
                  style={{
                    margin: '0 6px',
                    filter:
                      'brightness(0) saturate(100%) invert(62%) sepia(19%) saturate(146%) hue-rotate(131deg) brightness(90%) contrast(88%)',
                  }}
                />
              }
              type="default"
            ></IconButton>
          </Space>
        );
      },
      sorter: (a, b) =>
        new Date(a.lastViesCheck).valueOf() -
        new Date(b.lastViesCheck).valueOf(), //a.lastViesChack?.localeCompare(b.lastViesChack),
      sortIcon: renderSortIcon,
    },
    {
      title: 'Status',
      dataIndex: 'viesStatus',
      key: 'viesStatus',
      render: (content) => {
        return content ? (
          <StatusGetter status={ViesStatus[content.toUpperCase()]} noDraft />
        ) : null;
      },
      sorter: (a, b) => a.viesStatus.localeCompare(b.viesStatus),
      sortIcon: renderSortIcon,
    },

    {
      title: 'Actions',
      render: (_, record: Contact) => (
        <Space>
          <ScopeButton //@ts-ignore
            width="98px"
            height="25px"
            fontSize="12px"
            lineheight="12px"
            type="primary"
            onClick={() => getHistoryLogs(record)}
          >
            {t('vat-checker.history-log')}
          </ScopeButton>

          <Popover
            placement="topLeft"
            arrow={false}
            trigger="click"
            content={renderContextMenu(record)}
            title={''}
            overlayStyle={{ zIndex: 999 }}
          >
            <ContextButton type="text">
              <EllipseIcon
                style={{
                  height: '10px',
                }}
              />
            </ContextButton>
          </Popover>
        </Space>
      ),
    },
  ];
  const savePageSize = (pageSize: number) => {
    handlePreferences(PreferenceActions.SET, {
      name: PAGE_SIZE_VIES,
      value: pageSize,
    });
  };
  return (
    <div
      style={{
        padding: '40px',
        paddingTop: '10px',
        maxWidth: 1600,
        minWidth: 1320,
        margin: 'auto',
      }}
    >
      <Spin
        spinning={loading || !organisation}
        size="large"
        style={{ width: '100%', height: '100%' }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '25px',
          }}
        >
          <DashboardContainer
            className={
              selectedContaniers.includes(VIESContactStatistic.Customers)
                ? 'selected-container'
                : 'non-selected-container'
            }
            onClick={() => {
              let key: VIESContactStatistic | string =
                VIESContactStatistic.Customers;
              if (selectedContaniers.includes(VIESContactStatistic.Suppliers)) {
                filterContactsPerType(
                  VIESContactStatistic.Customers,
                  VIESContactStatistic.Suppliers
                );
              } else if (
                selectedContaniers.includes(VIESContactStatistic.Customers)
              ) {
                removeSelection(VIESContactStatistic.Customers);
                key = '';
              } else {
                filterContacts(VIESContactStatistic.Customers);
              }
              handlePreferences(PreferenceActions.SET, {
                name: FILTER_BOX_SELECTED,
                value: key,
              });
            }}
          >
            <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
              <UserIcon className="container-icon" />
              <Text className="container-title">
                {t('vat-checker.customers')}
              </Text>
              <Text className="container-number">
                {calcContactsType('CUSTOMER')}
              </Text>
            </Space>
          </DashboardContainer>
          <DashboardContainer
            className={
              selectedContaniers.includes(VIESContactStatistic.Suppliers)
                ? 'selected-container'
                : 'non-selected-container'
            }
            onClick={() => {
              let key: VIESContactStatistic | string =
                VIESContactStatistic.Suppliers;
              if (selectedContaniers.includes(VIESContactStatistic.Customers)) {
                filterContactsPerType(
                  VIESContactStatistic.Suppliers,
                  VIESContactStatistic.Customers
                );
              } else if (
                selectedContaniers.includes(VIESContactStatistic.Suppliers)
              ) {
                removeSelection(VIESContactStatistic.Suppliers);
                key = '';
              } else {
                filterContacts(VIESContactStatistic.Suppliers);
              }
              handlePreferences(PreferenceActions.SET, {
                name: FILTER_BOX_SELECTED,
                value: key,
              });
            }}
          >
            <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
              <StarIcon className="container-icon" />
              <Text className="container-title">
                {t('vat-checker.suppliers')}
              </Text>
              <Text className="container-number">
                {calcContactsType('SUPPLIER')}
              </Text>
            </Space>
          </DashboardContainer>
          <DashboardContainer
            className={
              selectedContaniers.includes(VIESContactStatistic.ContactsErrors)
                ? 'selected-container'
                : 'non-selected-container'
            }
            onClick={() =>
              selectedContaniers.includes(VIESContactStatistic.ContactsErrors)
                ? removeSelection('contacts-errors')
                : filterContacts('contacts-errors')
            }
          >
            <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
              <WarningIcon className="container-icon" />
              <Text className="container-title">
                {t('vat-checker.contacts-errors')}
              </Text>
              <Text className="container-number">{calcErrorsStatus()}</Text>
            </Space>
          </DashboardContainer>
          <DashboardContainer
            className={
              selectedContaniers.includes(VIESContactStatistic.StatusMissing)
                ? 'selected-container'
                : 'non-selected-container'
            }
            onClick={() =>
              selectedContaniers.includes(VIESContactStatistic.StatusMissing)
                ? removeSelection('status-missing')
                : filterContacts('status-missing')
            }
          >
            <Space direction="vertical" style={{ width: '100%', rowGap: 6 }}>
              <ExclamationIcon className="container-icon" />
              <Text className="container-title">
                {t('vat-checker.status-missing')}
              </Text>
              <Text className="container-number">
                {calcContactsStatus('Missing')}
              </Text>
            </Space>
          </DashboardContainer>
        </div>
        {
          //@ts-ignore
          <ScopeTable
            filters={['name', 'viesStatus', 'country']}
            rowSelection={{ type: 'checkbox', ...rowSelection }}
            mainLocaleKey="vat-checker"
            originalDataSource={contacts}
            tableDataSource={dataSource}
            columns={columns}
            pagination={true} //@ts-ignore
            rowsPerPage={pageSizeSaved ? pageSizeSaved : 20}
            renderRightComponent={renderCustomizeSection()}
            enableColumnsCustomization={false}
            setSelectedContanier={setSelectedContaniers}
            statusFilterKey={statusFilter}
            haveBorder
            showSizeChanger
            getNewPageSize={savePageSize}
            appendedFilterComponent={renderAppendedFilter()}
            clearAppendedFilterComponent={clearAppendedFilter}
          />
        }
      </Spin>
    </div>
  );
};
