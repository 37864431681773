import React, { useEffect, useState } from 'react';
import routes from 'routes';
import styled from 'styled-components';
import { ReactComponent as IconUsers } from 'assets/images/icons/iconUsers.svg';
import { ReactComponent as IconLogout } from 'assets/images/icons/iconLogout.svg';
import TestPic from 'assets/images/test-pic.png';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { logoutRequest } from 'store/auth/actions';
import { MenuSize } from 'store/app/enums';
import { Spin } from 'antd';
import { AvatarSizes, renderAvatar } from 'utils/render-avatar';
import { getAuthLoading, getAuthUser } from 'store/auth/selectors';
import { fetchProfilePictureRequest } from 'store/account/actions';
import { getProfilePictures } from 'store/app/selectors';
import { StyledTooltip } from './Tooltip';

const UserMenuWrapper = styled.div`
  margin-bottom: 75px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;

  .user-menu-button,
  .user-menu-button-small {
    height: 56px;
    border-radius: 10px;
    opacity: 0.5px;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border: 1px solid transparent;
    cursor: pointer;
    &:hover {
      background: #f3f7f8;
      border: 1px solid #41aaaf;
    }
  }
  .user-menu-button-small {
    background: none;
    &:hover {
      background: none;
      border: 1px solid transparent;
      .user-picture {
        border: 1px solid #41aaaf;
      }
    }
  }
  .user-picture {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    border: 1px solid transparent;
  }
  .user-button-caret {
    margin-top: 3px;
  }
  .user-menu-items {
    //width: 173px;
    height: 90px;
    border-radius: 10px;
    background: #ffffff;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 10px 10px 0px #0000000d;
  }
  .action-box-small,
  .action-box {
    margin: 8px 0 0 25px;
    //width: 94px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .action-box-small {
    margin-top: 15px;
  }
`;
interface UserMenuProps {
  menuSize: MenuSize;
}

export const UserMenu: React.FC<UserMenuProps> = ({ menuSize }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const onButtonClick = () => setMenuOpen(!menuOpen);
  const dispatch = useDispatch();
  const currentUser = useSelector(getAuthUser);
  const userLoading = useSelector(getAuthLoading);
  const userButtonWidth = menuSize === MenuSize.WIDE ? { width: 173 } : {};
  const itemsWidth = menuSize === MenuSize.WIDE ? { width: 173 } : {};
  const userButtonClass =
    menuSize === MenuSize.WIDE ? 'user-menu-button' : 'user-menu-button-small';
  const itemsBoxClass =
    menuSize === MenuSize.WIDE ? 'action-box' : 'action-box-small';
  const profilePopover = menuSize === MenuSize.WIDE ? '' : 'Profile';
  const logoutPopover = menuSize === MenuSize.WIDE ? '' : 'Logout';

  // useEffect(() => {
  //   if (currentUser) {
  //     if (currentUser.hasProfilePicture && !currentUser.profilePicture)
  //       dispatch(fetchProfilePictureRequest(currentUser.userUuid));
  //   }
  // }, [currentUser, profilePictures]);
  return (
    <Spin spinning={userLoading}>
      <UserMenuWrapper style={userButtonWidth}>
        {menuOpen && (
          <div
            className="user-menu-items"
            onMouseLeave={() => setMenuOpen(false)}
          >
            <StyledTooltip // @ts-ignore
              title={profilePopover}
              placement="right"
              arrow={false}
            >
              <div
                style={itemsWidth}
                className={itemsBoxClass}
                onClick={() => {
                  dispatch(push(routes.account.root));
                  setMenuOpen(false);
                }}
              >
                <IconUsers style={{ color: '#042A2A' }} />
                {menuSize === MenuSize.WIDE && (
                  <span style={{ marginLeft: '15px' }}>Profile</span>
                )}
              </div>
            </StyledTooltip>
            <StyledTooltip // @ts-ignore
              title={logoutPopover}
              placement="right"
              arrow={false}
            >
              <div
                className={itemsBoxClass}
                onClick={() => dispatch(logoutRequest())}
              >
                <IconLogout style={{ color: '#042A2A', marginLeft: 2 }} />
                {menuSize === MenuSize.WIDE && (
                  <span style={{ marginLeft: '17px' }}>Logout</span>
                )}
              </div>
            </StyledTooltip>
          </div>
        )}
        <div className={userButtonClass} onClick={onButtonClick}>
          {currentUser &&
            renderAvatar(
              currentUser?.firstName,
              currentUser?.lastName,
              AvatarSizes.DEFAULT,
              currentUser?.userUuid,
              currentUser?.profilePicture,
              'right'
            )}
          {menuSize === MenuSize.WIDE && (
            <>
              <span style={{ textAlign: 'center' }}>
                {currentUser?.firstName.substring(0, 1)}.{' '}
                {currentUser?.lastName}
              </span>
              <div className="user-button-caret">
                {!menuOpen ? <DownOutlined /> : <UpOutlined />}
              </div>
            </>
          )}
        </div>
      </UserMenuWrapper>
    </Spin>
  );
};
