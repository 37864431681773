import { StyledCard } from 'components/Custom/card';
import { ScopeTable } from 'components/Custom/table';
import * as React from 'react';
import { DataContact } from 'store/vat/models/vatReportData';
interface TableDataContact extends DataContact {
  key: number;
}
export const renderCyprusRecap = (contacts?: DataContact[]) => {
  const tableContacts: TableDataContact[] = [];
  contacts?.forEach((c, key) => tableContacts.push({ ...c, key }));
  const columns = [
    {
      title: 'Member state',
      dataIndex: 'country',
    },
    {
      title: 'Prefix',
      render: (_, record: DataContact) => {
        const vatNumber = record.taxNumber;
        const match = vatNumber?.match(/^[A-Za-z]+/);
        const prefix = match ? match[0] : '';
        return prefix;
      },
    },
    {
      title: 'VAT Registration Number',
      render: (_, record: DataContact) => {
        const vatNumber = record.taxNumber;
        const taxNumber = vatNumber?.replace(/\D/g, '');
        return taxNumber;
      },
    },
    {
      title: 'VAT Verified',
      dataIndex: 'viesStatus',
    },
    {
      title: 'Goods Subtotal',
      dataIndex: 'goodsSum',
      align: 'right',
      render: (_, record: DataContact) => {
        return record.goodsSum.toFixed(2);
      },
    },
    {
      title: 'Triangular Transactions',
      dataIndex: 'triangularTradeSum',
      align: 'right',
      render: (_, record: DataContact) => {
        return record.triangularTradeSum.toFixed(2);
      },
    },
    {
      title: 'Services Subtotal',
      dataIndex: 'servicesSum',
      align: 'right',
      render: (_, record: DataContact) => {
        return record.servicesSum.toFixed(2);
      },
    },
  ];

  return (
    <StyledCard //@ts-ignore
      width="100%"
    >
      <div
        style={{
          padding: '15px',
          paddingLeft: '5px',
          paddingTop: '0px',
          //maxWidth: 1600,
          minWidth: 800,
          margin: 'auto',
        }}
      >
        <ScopeTable
          filters={[]}
          className="recap-statement"
          originalDataSource={tableContacts} //@ts-ignore
          tableDataSource={tableContacts}
          pagination={true}
          rowsPerPage={10}
          enableColumnsCustomization={false}
          rowHeight={35}
          columns={columns}
          //titleComponent={null}
          getNewPageSize={() => {}}
        />
      </div>
    </StyledCard>
  );
};
