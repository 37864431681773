export enum PreferenceActions {
  GET,
  SET,
}
export enum PreferenceSections {
  VIES = 'vies',
  MAIN = 'main',
  ENTITIES = 'entities',
  USERS = 'users',
  TRANSACTIONS = 'transactions',
  EXCEPTIONS = 'exceptions',
}
export interface Preference {
  name: string;
  value?: any;
}
export interface Preferences {
  section: PreferenceSections;
  prefs: Preference[];
}
export const preferences = (
  action: PreferenceActions,
  section: PreferenceSections,
  preference: Preference,
  userId: string
  //value: any = null
) => {
  const fileName = `userPreferences-${userId}`;
  const checkFile = localStorage.getItem(fileName);
  let preferences: Preferences[] = [];
  const prefs: Preference[] = [];
  prefs.push(preference);
  if (checkFile) preferences = JSON.parse(checkFile);
  if (action === PreferenceActions.GET) {
    if (preferences.length === 0) return false;
    const sIdx = preferences.findIndex((p) => p.section === section);
    if (sIdx === -1) return false;
    const pIdx = preferences[sIdx]?.prefs.findIndex(
      (p) => p.name === preference.name
    );
    if (pIdx === -1) return false;
    return preferences[sIdx].prefs[pIdx].value;
  }
  if (action === PreferenceActions.SET) {
    if (preferences.length === 0) {
      preferences.push({ section, prefs });
    }
    const sIdx = preferences.findIndex((p) => p.section === section);
    if (sIdx === -1) {
      preferences.push({ section, prefs });
    }
    const pIdx = preferences[sIdx]?.prefs.findIndex(
      (p) => p.name === preference.name
    );
    if (pIdx === -1) {
      if (preferences[sIdx]) preferences[sIdx].prefs.push(preference);
    } else {
      if (preferences[sIdx]) preferences[sIdx].prefs[pIdx] = preference;
    }
    localStorage.setItem(fileName, JSON.stringify(preferences));
  }
};
