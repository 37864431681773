import { trustedDomains } from './trustedDomains';
import { ValidationResponse, Validator, ValidationValue } from './types';

export const composeValidators =
  (...validators: Validator[]) =>
  (value: ValidationValue): ValidationResponse =>
    validators.reduce(
      (error: ValidationResponse, validator: Validator) =>
        error || validator(value),
      undefined
    );

export function navigateToExternalPage(url: string) {
  if (trustedDomains.some((domain) => url.startsWith(domain))) {
    window.location.assign(url);
  } else {
    console.warn('Untrusted URL:', url);
  }
  //window.location.assign(url);
}
