import { call, delay, put, select } from 'redux-saga/effects';
import { ApiError } from 'store/api/types';
import { FetchContactsRequestAction } from '../types';
import { checkResponse } from 'store/utils';

import { GetContactsResponse, getContacts } from '../api';
import { turnMessageOn } from 'store/app/actions';
import { MessageData } from 'store/app/types';
import { MessageStates } from 'containers/MessageBox';
import {
  fetchContactsFailure,
  fetchContactsSuccess,
  fetchViesInfoRequest,
} from '../actions';
import { getViesInfos } from '../selectors';
import { getOrgOrganisations } from 'store/organisation/selectors';
import { fetchOrganisationsRequest } from 'store/organisation/actions';

/* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
export function* fetchContactsSaga(action: FetchContactsRequestAction) {
  try {
    let err: ApiError | null;
    const { connectionId } = action.payload;

    const response: GetContactsResponse = yield call(getContacts, connectionId);
    err = checkResponse(response?.responseMessage);
    if (err) throw err;

    const viesInfos = yield select(getViesInfos);

    let providerName = '';
    if (viesInfos.length === 0) {
      yield put(fetchViesInfoRequest(connectionId));
      let orgs = yield select(getOrgOrganisations);
      if (!orgs) {
        yield put(fetchOrganisationsRequest());
        yield delay(2000);
        orgs = yield select(getOrgOrganisations);
      }
      if (orgs)
        providerName = orgs.find(
          (o) => o.connectionId === connectionId
        ).providerName;
    } else {
      const selViesInfo = viesInfos.find(
        (vi) => vi.connectionId === connectionId
      );
      providerName = selViesInfo ? selViesInfo.providerName : '';
    }
    const contacts = response.data.contacts;

    contacts.map(function (c, index) {
      c.key = c.contactUuid;
      c.providerName = providerName;
    });
    yield put(fetchContactsSuccess(contacts));
  } catch (error: any) {
    const message: MessageData = {
      title: 'Server error',
      description: error.message,
      type: MessageStates.ERROR,
    };
    yield put(turnMessageOn(message));
    yield put(fetchContactsFailure());
  }
}
