export const FETCH_VIES_INFO_REQUEST = 'vat-checker/fetch-vies-info-request';
export const FETCH_VIES_INFO_SUCCESS = 'vat-checker/fetch-vies-info-success';
export const FETCH_VIES_INFO_FAILURE = 'vat-checker/fetch-vies-info-failure';

export const ACTIVATE_VIES_REQUEST = 'vat-checker/activate-vies-request';
export const ACTIVATE_VIES_SUCCESS = 'vat-checker/activate-vies-success';
export const ACTIVATE_VIES_FAILURE = 'vat-checker/activate-vies-failure';

export const DEACTIVATE_VIES_REQUEST = 'vat-checker/deactivate-vies-request';
export const DEACTIVATE_VIES_SUCCESS = 'vat-checker/deactivate-vies-success';
export const DEACTIVATE_VIES_FAILURE = 'vat-checker/deactivate-vies-failure';

export const FETCH_CONTACTS_REQUEST = 'vat-checker/fetch-contacts-request';
export const FETCH_CONTACTS_SUCCESS = 'vat-checker/fetch-contacts-success';
export const FETCH_CONTACTS_FAILURE = 'vat-checker/fetch-contacts-failure';

export const FETCH_CONTACT_REQUEST = 'vat-checker/fetch-contact-request';
export const FETCH_CONTACT_SUCCESS = 'vat-checker/fetch-contact-success';
export const FETCH_CONTACT_FAILURE = 'vat-checker/fetch-contact-failure';

export const CHECK_CONTACTS_REQUEST = 'vat-checker/check-contacts-request';
export const CHECK_CONTACTS_SUCCESS = 'vat-checker/check-contacts-success';
export const CHECK_CONTACTS_FAILURE = 'vat-checker/check-contacts-failure';

export const CHECK_CONTACT_REQUEST = 'vat-checker/check-contact-request';
export const CHECK_CONTACT_SUCCESS = 'vat-checker/check-contact-success';
export const CHECK_CONTACT_FAILURE = 'vat-checker/check-contact-failure';

export const REFRESH_CONTACTS_REQUEST = 'vat-checker/refresh-contacts-request';
export const REFRESH_CONTACTS_SUCCESS = 'vat-checker/refresh-contacts-success';
export const REFRESH_CONTACTS_FAILURE = 'vat-checker/refresh-contacts-failure';

export const REFRESH_CONTACT_REQUEST = 'vat-checker/refresh-contact-request';
export const REFRESH_CONTACT_SUCCESS = 'vat-checker/refresh-contact-success';
export const REFRESH_CONTACT_FAILURE = 'vat-checker/refresh-contact-failure';

export const HIDE_CONTACT_REQUEST = 'vat-checker/hide-contact-request';
export const HIDE_CONTACT_SUCCESS = 'vat-checker/hide-contact-success';
export const HIDE_CONTACT_FAILURE = 'vat-checker/hide-contact-failure';
