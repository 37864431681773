import * as React from 'react';
import { Col, Form, Row, Typography } from 'antd';
import { ScopeButton } from 'components/Custom/buttons';
import { useDispatch } from 'react-redux';
import { turnDrawerOff } from 'store/app/actions';
import { FormSelect } from 'components/Custom/selects';
import { LabelWrapper } from 'components/Custom/labels';
import { FormDatePicker } from 'components/Custom/datePicker';
import { useTranslation } from 'react-i18next';
import { DataJournal } from 'store/vat/models/vatReportData';

type FieldType = {
  adjustmentPeriod?: string;
  captureDate?: Date;
};

enum FieldNames {
  ADJUSTMENT_PERIOD = 'adjustmentPeriod',
  CAPTURE_DATE = 'captureDate',
}

interface AdjustmentFormProps {
  selectedRows?: DataJournal[];
  saveAdjustment: (adjustmentPeriod?: string, captureDate?: Date) => void;
}

//const { Text, Title } = Typography;
export const AdjustmentForm: React.FC<AdjustmentFormProps> = ({
  selectedRows,
  saveAdjustment,
}) => {
  const [selectedAdjustmentPeriod, setSelectedAdjustmentPeriod] =
    React.useState<string>();
  const [selectedCaptureDate, setSelectedCaptureDate] = React.useState<any>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    saveAdjustment(selectedAdjustmentPeriod, selectedCaptureDate);
    dispatch(turnDrawerOff());
  };

  const onAdjustmentPeriodChange = () => {};

  const onCaptureDateChange = (date: any) => {
    form.validateFields([[FieldNames.CAPTURE_DATE]]);

    if (date.isAfter(selectedCaptureDate)) {
      form.resetFields(['returnDueDate']);
    }
    setSelectedCaptureDate(date);
  };

  return (
    <>
      <Typography
        style={{
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '18px',
          textAlign: 'left',
          color: '#042A2A',
          marginBottom: '40px',
        }}
      >
        {t('exceptions.adjustment-desc')}
      </Typography>
      <Row justify={'center'}>
        <Col span={24}>
          <Form
            name="basic"
            form={form}
            autoComplete="off"
            onFinish={handleSubmit}
          >
            <LabelWrapper>
              <span className="label-text">
                {t('exceptions.adjustment-period')}
              </span>
            </LabelWrapper>
            <Form.Item<FieldType>
              name="adjustmentPeriod"
              rules={[
                {
                  message: t('common.required-field'),
                  validator: () => {
                    if (!selectedAdjustmentPeriod) {
                      return Promise.reject('Message problem');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <FormSelect
                placeholder={t('common.select')}
                onChange={onAdjustmentPeriodChange} //@ts-ignore
                width="100%"
                height="45px"
                bordercolor="#DBEAE3"
                bgcolor="#FFFFFF"
              >
                {/*returnTypes?.map((rt) => (
                  <Select.Option key={rt.id} value={rt.id}>
                    <span
                      key={rt.id}
                      style={editMode ? { color: '#2E2E2E' } : {}}
                    >
                      {rt.name}
                    </span>
                  </Select.Option>
                ))*/}
              </FormSelect>
            </Form.Item>

            
            <LabelWrapper>
              <span className="label-text">{t('exceptions.capture-date')}</span>
            </LabelWrapper>
            <Form.Item<FieldType>
              name="captureDate"
              rules={[
                {
                  message: t('common.required-field'),
                  validator: () => {
                    if (!selectedCaptureDate) {
                      return Promise.reject('Message problem');
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <FormDatePicker
                style={{ width: '100%' }}
                placeholder={t('common.select')}
                onChange={onCaptureDateChange}
                format="DD/MM/YYYY" //@ts-ignore
                hasValue={!!selectedCaptureDate}
              />
            </Form.Item>

            <Form.Item>
              <ScopeButton
                type="primary"
                htmlType="submit" //@ts-ignore
                width="100%"
              >
                {t('common.save')}
              </ScopeButton>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
