import { Col, Form, Row, Transfer, TransferProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Section, TaxRate } from 'store/returns/models/returnData';
import { MappingData } from 'store/returns/models';
import { SelectedRate, TaxRateOption } from 'pages/returns';
import { ReactComponent as IconQUestionMark } from 'assets/images/icons/iconQuestionMark.svg';
import { FormSelect } from 'components/Custom/selects';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ScopeButton } from 'components/Custom/buttons';
import { StyledPopconfirm } from 'components/Custom/Popconfirm';
import { StyledTooltip } from 'components/Custom/Tooltip';
import { TaxRateType } from 'store/app/enums';

interface RecordType {
  key: string;
  title: string;
  description: string;
}

const MapTaxRatesWrapper = styled.div`
  margin-bottom: 50px;
  .box-column {
    width: 100px;
  }
  .box-column-space {
    width: 5px;
  }

  .mapping-label {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    color: #879494;
    display: flex;
    gap: 5px;
    margin-bottom: 5px;
  }

  .mapping-section {
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 35px;
    display: flex;
    justify-content: space-between;
  }

  .system-generated {
    height: 45px;
    border-radius: 2px;
    background: #e9f6f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #879494;
    font-size: 14px;
    font-weight: 400;
    padding: 0 25px;
    width: 100%;
  }

  .mapping-box,
  .mapping-box-oos {
    min-width: 80px;
    height: 45px;
    border-radius: 2px;
    background: #fff5e2;
    border: 1px solid #dbeae3;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .mapping-box-oos {
    background: #ffe9e2;
  }
  .oos-link {
    font-size: 14px;
    font-weight: 800;
    color: #058181;
  }
  .ant-transfer-list-content {
    background: #f3f7f8;
  }
  .ant-transfer-list-header {
    background: #fff5e2;
  }
  .ant-transfer-list-body {
    background: #f3f7f8;
  }
  .ant-transfer-list-header-title {
    font-size: 18px;
    font-weight: 600;
  }
  .ant-empty-image {
    display: none;
  }
`;

interface MapTaxRatesProps {
  sections?: Section[];
  taxRateOptions?: TaxRateOption[];
  outOfScopeOptions?: TaxRateOption[];
  outOfScopeCode?: string;
  handleTaxRangeChange: (
    rateIds: string[],
    outOfScope: boolean,
    code: string
  ) => void;
  form: any;
  mappingData?: MappingData;
  taxRates?: TaxRate[];
  updateSelectedRates: (mappedData: SelectedRate[]) => void;
  setOptions: (trOptions: TaxRateOption[], oosOptions: TaxRateOption[]) => void;
  prepareTaxRateOptions: () => TaxRateOption[];
  onSubmit: () => void;
  resetForm: () => void;
  addUnmapped: () => void;
  useCashReverse: boolean;
  selectedRates?: SelectedRate[];
  updateTaxRateType: (type: TaxRateType, rateId: string | any[]) => void;
  disabled?: boolean;
}
const { Text } = Typography;
export const MapTaxRates: React.FC<MapTaxRatesProps> = ({
  sections,
  taxRateOptions,
  outOfScopeOptions,
  outOfScopeCode,
  handleTaxRangeChange,
  form,
  mappingData,
  taxRates,
  updateSelectedRates,
  setOptions,
  prepareTaxRateOptions,
  onSubmit,
  resetForm,
  addUnmapped,
  useCashReverse,
  selectedRates,
  updateTaxRateType,
  disabled,
}) => {
  const { t } = useTranslation();
  const SYSTEM_GENERATED = 'SYSTEM_GENERATED';

  useEffect(() => {
    let isMounted = true;

    if (mappingData?.templateMappingId) {
      const trOptions: TaxRateOption[] = prepareTaxRateOptions();
      const oosOptions: TaxRateOption[] = prepareTaxRateOptions();

      const md: SelectedRate[] = [];
      const oosCode = sections?.find((s) => s.subsections[0].outOfScope)
        ?.subsections[0].code;
      const caCode = sections?.find(
        (s) => s.name === s.subsections[0].code
      )?.name;
      const jsonMapping = mappingData?.jsonMapping;
      const newValues = {};

      jsonMapping?.map((jm) => {
        //const taxRateId = taxRates?.find((tr) => tr.taxType === jm.taxType)?.taxType;
        const taxRateId = jm.taxType;
        const value = taxRateId ? taxRateId : '';
        const idx = trOptions.findIndex((opt) => opt.value === value);
        if (idx > -1) {
          const codes = jm.codes?.split(',');
          const loc = codes ? codes : [];
          let cashAccounting = false;
          const reverseCharge: number | null =
            jm.reverseCharge !== null && parseFloat(jm.reverseCharge) > 0
              ? parseFloat(jm.reverseCharge)
              : null;
          //let reverseCharge: number | null = null;
          //if (jm.reverseCharge) parseFloat(jm.reverseCharge).toFixed(2);
          const taxRateType = jm.taxRateType
            ? jm.taxRateType
            : TaxRateType.INPUT;
          codes?.map((c) => {
            let boxCode = c;
            if (c === oosCode) {
              boxCode = 'outOfScope';
            }
            if (c === caCode) {
              cashAccounting = true;
            } else {
              if (Array.isArray(newValues[boxCode]))
                newValues[boxCode].push(taxRateId);
              else {
                newValues[boxCode] = [taxRateId];
              }
            }
          });
          if (codes) {
            if (codes[0] === oosCode) {
              !!taxRateId &&
                trOptions.splice(
                  trOptions.findIndex((t) => t.value === taxRateId),
                  1
                );
            } else {
              oosOptions.splice(
                oosOptions.findIndex((o) => o.value === taxRateId),
                1
              );
            }
          }
          const location = loc.filter((l) => l !== caCode);
          !!value &&
            md.push({
              value,
              location,
              cashAccounting,
              reverseCharge,
              taxRateType,
            });
        }
      });

      try {
        if (isMounted) {
          selectedRates?.forEach((sr) => {
            const idx = md.findIndex((m) => m.value === sr.value);
            if (idx === -1) md.push(sr);
          });
          if (jsonMapping && jsonMapping.length > 0) updateSelectedRates(md);
          setOptions(trOptions, oosOptions);
          form.resetFields();
          form.setFieldsValue(newValues);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [mappingData]);

  const NOT_USED = 'NOT_USED';
  const initialTargetKeys = [];
  const [targetKeys, setTargetKeys] =
    useState<TransferProps['targetKeys']>(initialTargetKeys);
  const [selectedKeys, setSelectedKeys] = useState<TransferProps['targetKeys']>(
    []
  );
  const [taxRateTypeData, setTaxRateTypeData] =
    useState<TransferProps['dataSource']>();

  useEffect(() => {
    if (selectedRates) {
      const data_pre = Array.from({
        length: selectedRates?.length,
      }).map<RecordType>((_, i) => {
        const notUsed =
          selectedRates[i].location.length === 0 ||
          selectedRates[i].location[0] === outOfScopeCode;
        const title = taxRates?.find(
          (tr) => tr.taxType === selectedRates[i].value
        )?.name;
        return {
          key: selectedRates[i].value,
          title: notUsed ? NOT_USED : title ? title : '',
          description: title && !notUsed ? title : '',
        };
      });
      const data = data_pre.filter((d) => d.title !== NOT_USED);
      const targetKeys: TransferProps['targetKeys'] = data
        .filter((item) => {
          const nIdx = selectedRates.findIndex((sr) => sr.value === item.key);
          if (
            nIdx !== -1 &&
            selectedRates[nIdx].taxRateType === TaxRateType.OUTPUT
          )
            return item;
        })
        .map((item) => item.key);

      setTaxRateTypeData(data);
      setTargetKeys(targetKeys);
    }
  }, [selectedRates]);

  const onTaxRateTypeChange: TransferProps['onChange'] = (nextTargetKeys) => {
    updateTaxRateType(TaxRateType.OUTPUT, nextTargetKeys);
    // selectedRates.forEach((sr) => {
    //   const nIdx = nextTargetKeys.findIndex((ntg) => ntg === sr.value);
    //   if (nIdx === -1) updateTaxRateType(TaxRateType.INPUT, sr.value);
    //   else updateTaxRateType(TaxRateType.OUTPUT, sr.value);
    // });

    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange: TransferProps['onSelectChange'] = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const renderTaxRateTypeSelection = () => {
    return (
      <Transfer
        dataSource={taxRateTypeData}
        titles={['Input', 'Output']}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={onTaxRateTypeChange}
        onSelectChange={onSelectChange}
        render={(item) => item.title}
        listStyle={{
          width: 400,
          height: 260,
        }}
        selectAllLabels={[
          ({ selectedCount, totalCount }) => (
            <span>
              {selectedCount === 0
                ? `${totalCount} tax rate${totalCount > 1 ? 's' : ''}`
                : `${selectedCount} of ${totalCount} tax rate${
                    totalCount > 1 ? 's' : ''
                  }`}
            </span>
          ),
          ({ selectedCount, totalCount }) => (
            <span>
              {selectedCount === 0
                ? `${totalCount} tax rate${totalCount > 1 ? 's' : ''}`
                : `${selectedCount} of ${totalCount} tax rate${
                    totalCount > 1 ? 's' : ''
                  }`}
            </span>
          ),
        ]}
      />
    );
  };
  return (
    <MapTaxRatesWrapper>
      <Form name="basic" form={form} autoComplete="off">
        {sections?.map((s, idx) => (
          <div key={`section${idx}`} style={{ marginBottom: 20 }}>
            {s.name !== s.subsections[0].code && (
              <Row key={`row${idx}`}>
                <Col key={`Col${idx}`} span={24} className="mapping-section">
                  <span>{s.name}</span>
                  {s.subsections.findIndex((ss) => ss.outOfScope) > -1 && (
                    <ScopeButton //@ts-ignore
                      width="250px"
                      height="30px"
                      type={'default'}
                      fontSize="12px"
                      lineheight="12px"
                      onClick={() => addUnmapped()}
                      disabled={useCashReverse || disabled}
                    >
                      Mark Remaining as Out of Scope
                    </ScopeButton>
                  )}
                </Col>
                <Col key={`Col2${idx}`} span={24}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      {s.subsections.map((ss, idx) => (
                        <React.Fragment key={ss.id}>
                          <tr>
                            <td className="mapping-label">
                              {ss.name} <IconQUestionMark />
                            </td>
                            <td className="box-column-space" />
                            <td className="box-column" />
                          </tr>
                          <tr>
                            <td>
                              {ss.type === SYSTEM_GENERATED ? (
                                <>
                                  <div className="system-generated">
                                    <span>Totals</span>
                                    <span style={{ color: '#042A2A' }}>
                                      <i>System Generated</i>
                                    </span>
                                  </div>
                                </>
                              ) : (
                                <Form.Item
                                  name={ss.outOfScope ? 'outOfScope' : ss.code}
                                  style={{ marginBottom: 0 }}
                                >
                                  <FormSelect //@ts-ignore
                                    width="100%"
                                    height="45px"
                                    mode="multiple"
                                    textalign={'start'}
                                    placeholder="Select"
                                    options={
                                      ss.outOfScope
                                        ? outOfScopeOptions
                                        : taxRateOptions
                                    }
                                    onChange={(e: any) =>
                                      handleTaxRangeChange(
                                        e,
                                        ss.outOfScope,
                                        ss.code
                                      )
                                    }
                                    key={ss.id}
                                    allowClear
                                    showSearch
                                    bordercolor="#DBEAE3"
                                    bgcolor="#F3F7F8"
                                    selected_bg_color="#FFFFFF"
                                    radius="2px"
                                    maxTagCount={
                                      ss.outOfScope ? undefined : 'responsive'
                                    }
                                    disabled={useCashReverse || disabled} //@ts-ignore
                                    filterOption={(input, option) =>
                                      option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    maxTagPlaceholder={(omittedValues) => (
                                      <StyledTooltip //@ts-ignore
                                        overlayStyle={{ pointerEvents: 'none' }}
                                        title={
                                          <>
                                            {omittedValues.map(({ label }) => (
                                              <>
                                                <span>{label}</span>
                                                <br />
                                              </>
                                            ))}
                                          </>
                                        }
                                      >
                                        <span>+{omittedValues.length}</span>
                                      </StyledTooltip>
                                    )}
                                  />
                                </Form.Item>
                              )}
                            </td>
                            <td className="box-column-space" />
                            <td className="box-column">
                              <div
                                className={
                                  ss.outOfScope
                                    ? 'mapping-box-oos'
                                    : 'mapping-box'
                                }
                              >
                                <Text>{ss.code}</Text>
                              </div>
                            </td>
                          </tr>
                          <tr style={{ height: 25 }} />
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </Col>
              </Row>
            )}
          </div>
        ))}
        {!disabled && (
          <>
            <Row>
              <Col
                span={24}
                className="mapping-section"
                style={{ flexDirection: 'column' }}
              >
                <span>Tax Rate Type</span>
                <div className="divider-10" />
                <span className="mapping-label">
                  Select the tax rates and use the arrows to move them between
                  'Input' and 'Output'. This is important for generate.TAX to
                  compute the data in the return.
                </span>
                <div className="divider-10" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  {renderTaxRateTypeSelection()}
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 24 }} justify="space-between" gutter={48}>
              <Col span={12}>
                <StyledPopconfirm // @ts-ignore
                  placement="left"
                  title="Reset form"
                  description="Are you sure you want to remove all mapping data?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={resetForm}
                >
                  <ScopeButton
                    type="default" //@ts-ignore
                    width="100%"
                    disabled={useCashReverse}
                  >
                    {t('common.reset')}
                  </ScopeButton>
                </StyledPopconfirm>
              </Col>
              <Col span={12}>
                <ScopeButton
                  onClick={() => onSubmit()}
                  type="primary" //@ts-ignore
                  width="100%"
                  disabled={useCashReverse}
                >
                  {t('common.save')}
                </ScopeButton>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </MapTaxRatesWrapper>
  );
};
