import { ViesStatus } from 'store/app/enums';
import {
  ACTIVATE_VIES_FAILURE,
  ACTIVATE_VIES_REQUEST,
  ACTIVATE_VIES_SUCCESS,
  CHECK_CONTACT_FAILURE,
  CHECK_CONTACT_REQUEST,
  CHECK_CONTACT_SUCCESS,
  CHECK_CONTACTS_FAILURE,
  CHECK_CONTACTS_REQUEST,
  CHECK_CONTACTS_SUCCESS,
  DEACTIVATE_VIES_FAILURE,
  DEACTIVATE_VIES_REQUEST,
  DEACTIVATE_VIES_SUCCESS,
  FETCH_CONTACT_FAILURE,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_VIES_INFO_FAILURE,
  FETCH_VIES_INFO_REQUEST,
  FETCH_VIES_INFO_SUCCESS,
  HIDE_CONTACT_FAILURE,
  HIDE_CONTACT_REQUEST,
  HIDE_CONTACT_SUCCESS,
  REFRESH_CONTACT_FAILURE,
  REFRESH_CONTACT_REQUEST,
  REFRESH_CONTACT_SUCCESS,
  REFRESH_CONTACTS_FAILURE,
  REFRESH_CONTACTS_REQUEST,
  REFRESH_CONTACTS_SUCCESS,
} from './constants';
import {
  ActivateViesSuccessAction,
  CheckContactsSuccessAction,
  CheckContactSuccessAction,
  DeactivateViesSuccessAction,
  FetchContactsSuccessAction,
  FetchContactSuccessAction,
  FetchViesInfoSuccessAction,
  HideContactSuccessAction,
  VatCheckerAction,
  ViesState,
} from './types';
import { Contact } from './models';

const initialState: ViesState = {
  loading: false,
  viesInfos: [],
  contacts: undefined,
  viesChecks: undefined,
};

export const vatCheckerReducer = (
  state = initialState,
  action: VatCheckerAction
): ViesState => {
  switch (action.type) {
    case ACTIVATE_VIES_REQUEST:
    case DEACTIVATE_VIES_REQUEST:
    case CHECK_CONTACTS_REQUEST:
    case CHECK_CONTACT_REQUEST:
    case REFRESH_CONTACTS_REQUEST:
    case REFRESH_CONTACT_REQUEST:
    case HIDE_CONTACT_REQUEST:
      return handleRequest(state);
    case FETCH_CONTACTS_REQUEST:
      return handleFetchContactsRequest(state);
    case FETCH_CONTACT_REQUEST:
      return handleFetchContactRequest(state);
    case FETCH_VIES_INFO_REQUEST:
      return handleFetchViesInfoRequest(state);
    case FETCH_VIES_INFO_SUCCESS:
      return handleFetchViesInfoSuccess(state, action);
    case FETCH_CONTACTS_SUCCESS:
      return handleFetchContactsSuccess(state, action);
    case CHECK_CONTACTS_SUCCESS:
      return handleCheckContactsSuccess(state, action);
    case CHECK_CONTACT_SUCCESS:
      return handleCheckContactSuccess(state, action);
    case FETCH_CONTACT_SUCCESS:
      return handleFetchContactSuccess(state, action);
    case ACTIVATE_VIES_SUCCESS:
      return handleActivateViesSuccess(state, action);
    case DEACTIVATE_VIES_SUCCESS:
      return handleDeactivateViesSuccess(state, action);
    case HIDE_CONTACT_SUCCESS:
      return handleHideContactSuccess(state, action);
    case REFRESH_CONTACTS_SUCCESS:
    case REFRESH_CONTACT_SUCCESS:
      return handleSuccess(state);
    case ACTIVATE_VIES_FAILURE:
    case DEACTIVATE_VIES_FAILURE:
    case FETCH_VIES_INFO_FAILURE:
    case FETCH_CONTACTS_FAILURE:
    case FETCH_CONTACT_FAILURE:
    case CHECK_CONTACT_FAILURE:
    case CHECK_CONTACTS_FAILURE:
    case REFRESH_CONTACTS_FAILURE:
    case REFRESH_CONTACT_FAILURE:
    case HIDE_CONTACT_FAILURE:
      return handleFailure(state);
    default:
      return state;
  }
};

function handleFetchViesInfoRequest(state: ViesState): ViesState {
  return {
    ...state,
    loading: state.viesInfos.length === 0,
  };
}

function handleFetchContactRequest(state: ViesState): ViesState {
  return {
    ...state,
    loading: true,
    viesChecks: undefined,
  };
}

function handleFetchContactsRequest(state: ViesState): ViesState {
  return {
    ...state,
    loading: true,
    contacts: undefined,
  };
}

function handleFetchViesInfoSuccess(
  state: ViesState,
  action: FetchViesInfoSuccessAction
): ViesState {
  const viesInfos = [...state.viesInfos];
  const newViesInfos = action.payload.viesInfos;
  if (newViesInfos) {
    newViesInfos.forEach((nvi) => {
      const idx = viesInfos.findIndex(
        (vi) => vi.connectionId === nvi.connectionId
      );
      if (idx === -1) viesInfos.push(nvi);
      else viesInfos[idx] = nvi;
    });
  }
  //localStorage.setItem('vies-info-local', JSON.stringify(viesInfos));
  return {
    ...state,
    loading: false,
    viesInfos,
  };
}

function handleFetchContactsSuccess(
  state: ViesState,
  action: FetchContactsSuccessAction
): ViesState {
  return {
    ...state,
    loading: false,
    contacts: action.payload.contacts,
  };
}

function handleCheckContactsSuccess(
  state: ViesState,
  action: CheckContactsSuccessAction
): ViesState {
  const lastViesCheckDate = action.payload.lastViesCheckDate;
  const viesInfos = state.viesInfos;
  const idx = viesInfos.findIndex(
    (vi) => (vi.connectionId = action.payload.connectionId)
  );
  const contacts = action.payload.contacts;

  if (idx > -1) {
    viesInfos[idx] = { ...viesInfos[idx], lastViesCheckDate };
    const providerName = viesInfos[idx].providerName;
    contacts?.map(function (c) {
      c.providerName = providerName;
      return c;
    });
  }

  return {
    ...state,
    loading: false,
    contacts: action.payload.contacts,
  };
}

function handleFetchContactSuccess(
  state: ViesState,
  action: FetchContactSuccessAction
): ViesState {
  const viesChecks = action.payload.viesChecks;
  const contacts = state.contacts ? state.contacts : undefined;
  viesChecks?.sort(function (a, b) {
    return (
      new Date(b.viesCheckedDate).valueOf() -
      new Date(a.viesCheckedDate).valueOf()
    );
  });
  // const contact = action.payload.contact;
  // if (contact && contacts) {
  //   const idx = contacts.findIndex(
  //     (c) => c.contactUuid === contact.contactUuid
  //   );
  //   if (idx > -1) {
  //     contacts[idx] = contact;
  //   }
  // }
  return {
    ...state,
    loading: false,
    viesChecks,
    contacts,
  };
}

function handleCheckContactSuccess(
  state: ViesState,
  action: CheckContactSuccessAction
): ViesState {
  const viesChecks = action.payload.viesChecks ? action.payload.viesChecks : [];
  const updatedContact = action.payload.contact;
  const contacts = state.contacts ? state.contacts : [];
  const idx = contacts?.findIndex(
    (c) => c.contactUuid === updatedContact.contactUuid
  );
  viesChecks?.sort(function (a, b) {
    return (
      new Date(b.viesCheckedDate).valueOf() -
      new Date(a.viesCheckedDate).valueOf()
    );
  });
  if (idx > -1) {
    contacts[idx] = {
      ...contacts[idx],
      lastValidCheck:
        ViesStatus[viesChecks[0].viesStatus] === ViesStatus.VALID
          ? viesChecks[0]
          : undefined,
      lastViesCheck: viesChecks[0].viesCheckedDate,
      viesStatus: viesChecks[0].viesStatus,
    };
  }
  return {
    ...state,
    loading: false,
    viesChecks,
    contacts,
  };
}

function handleActivateViesSuccess(
  state: ViesState,
  action: ActivateViesSuccessAction
): ViesState {
  const connectionIdToUpdate = action.payload.connectionId;
  const viesInfos = [...state.viesInfos];
  const idx = viesInfos.findIndex(
    (vi) => vi.connectionId === connectionIdToUpdate
  );
  if (idx > -1) viesInfos[idx].vatCheckerActive = true;

  return {
    ...state,
    loading: false,
    viesInfos,
  };
}

function handleDeactivateViesSuccess(
  state: ViesState,
  action: DeactivateViesSuccessAction
): ViesState {
  const connectionIdToUpdate = action.payload.connectionId;
  const viesInfos = [...state.viesInfos];
  const idx = viesInfos.findIndex(
    (vi) => vi.connectionId === connectionIdToUpdate
  );
  if (idx > -1) viesInfos[idx].vatCheckerActive = false;

  return {
    ...state,
    loading: false,
    viesInfos,
  };
}

function handleHideContactSuccess(
  state: ViesState,
  action: HideContactSuccessAction
): ViesState {
  const { hideContactData } = action.payload;
  const isVisible = hideContactData.isActive;
  const contactsToHide = hideContactData.contactsToHide;
  let contacts: Contact[] = [];
  if (state.contacts) contacts = [...state.contacts];

  if (hideContactData) {
    contactsToHide.forEach((cth) => {
      const nIdx = contacts.findIndex((c) => c.contactUuid === cth);
      if (nIdx !== -1) contacts[nIdx].isVisible = isVisible;
    });
  }
  return {
    ...state,
    loading: false,
    contacts,
  };
}

// Common
function handleRequest(state: ViesState): ViesState {
  return {
    ...state,
    loading: true,
  };
}

function handleSuccess(state: ViesState): ViesState {
  return {
    ...state,
    loading: false,
  };
}

function handleFailure(state: ViesState): ViesState {
  return {
    ...state,
    loading: false,
  };
}
