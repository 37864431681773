export enum MessageType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export enum MessageStatus {
  OK = 'OK',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum Provider {
  XERO = 'xero',
  QUICKBOOKS = 'quickbooks',
  GOOGLE = 'google',
  CIN7 = 'dear',
  AZURE = 'azure',
}

export enum ErrorCode {
  EMPTY_CODE,
  CONNECTION_ERROR,
  USER_ERROR,
  ORGANISATION_ERROR,
  KEY_ERROR,
  PROMO_ERROR,
  NO_ERROR,
}

export enum ReturnFrequency {
  BI_YEARLY = '2 Years',
  FORNIGHTLY = 'Fortnightly',
  MONTHLY = 'Monthly',
  TWO_MONTHS = '2 Months',
  SIX_MONTHS = '6 Months',
  QUARTERLY = 'Quarterly',
  WEEKLY = 'Weekly',
  YEARLY = 'Yearly',
}

export enum AccountType {
  OWNER = 'Owner',
  STAFF = 'User',
  EXSTAFF = 'Ex-staff',
  SUPPORT = 'Support',
  ADMIN = 'Admin',
  DDEXPERT = 'DD-Expert',
  UNSPECIFIED = 'Unspecified',
  CLIENT = 'Client',
  REVENUE = 'Revenue',
  DIRECTCOSTS = 'Direct Costs',
  CURRENT = 'Current',
  CURRLIAB = 'Current Liab',
  EXPENSE = 'Expense',
  FIXED = 'Fixed',
}

export enum PermissionType {
  ORGANISATIONS = 'organisations',
  USERS = 'users',
}

export enum TablePermission {
  READ = 'READ',
  WRITE = 'WRITE',
  ALL = 'ALL',
  NONE = 'NONE',
  OWNER_NONE = 'OWNER_NONE',
}

export enum DateOptions {
  ALL = 'All',
  TODAY = 'Today',
  THIS_MONTH = 'This month',
  NEXT_MONTH = 'Next month',
  THIS_WEEK = 'This week',
  NEXT_WEEK = 'Next week',
  THIS_QUARTER = 'This quarter',
  NEXT_QUARTER = 'Next quarter',
}

export enum JournalType {
  SUMMARISED = 'Summarised ',
  DETAILED = 'Detailed',
}

// export enum SourceType {
//   ACCREC = 'Account Received',
//   CASHPAID = 'Cash Paid',
//   ACCPAY = 'Account Pay',
//   ACCRECCREDIT = 'Account Received credit',
//   CASHREC = 'Receive direct payment',
//   ARPREPAYMENT = 'Receive payment',
//   APPREPAYMENT = 'Spend payment',
//   AROVERPAYMENT = 'Receive overpayment',
//   APOVERPAYMENT = 'Spend overpayment',
//   MANJOURNAL = 'Manual journal',
//   ACCRECPAYMENT = 'Receive payment',
//   ACCPAYPAYMENT = 'Spend payment',
//   ARCREDITPAYMENT = 'Receive credit note payment',
//   APCREDITPAYMENT = 'Spend credit note payment',
//   CREDITNOTE_REVERSAL = 'Credit note reversal',
//   CREDITNOTE_ALLOCATION = 'Credit note allocation',
//   PREPAYMENT_ALLOCATION = 'Prepayment allocation',
//   OVERPAYMENT_ALLOCATION = 'Overpayment allocation',
// }
export enum SourceType {
  ACCREC = 'Accounts Receivable Invoice',
  ACCPAY = 'Accounts Payable Invoice',
  ACCRECCREDIT = 'Accounts Receivable Credit Note',
  ACCPAYCREDIT = 'Accounts Payable Credit Note',
  ACCRECPAYMENT = 'Payment on an Accounts Receivable Invoice',
  ACCPAYPAYMENT = 'Payment on an Accounts Payable Invoice',
  ARCREDITPAYMENT = 'Accounts Receivable Credit Note Payment',
  APCREDITPAYMENT = 'Accounts Payable Credit Note Payment',
  CASHREC = 'Receive Money Bank Transaction',
  CASHPAID = 'Spend Money Bank Transaction',
  TRANSFER = 'Bank Transfer',
  ARPREPAYMENT = 'Accounts Receivable Prepayment',
  APPREPAYMENT = 'Accounts Payable Prepayment',
  AROVERPAYMENT = 'Accounts Receivable Overpayment',
  APOVERPAYMENT = 'Accounts Payable Overpayment',
  EXPCLAIM = 'Expense Claim',
  EXPPAYMENT = 'Expense Claim Payment',
  MANJOURNAL = 'Manual Journal',
  PAYSLIP = 'Payslip',
  WAGEPAYABLE = 'Payroll Payable',
  INTEGRATEDPAYROLLPE = 'Payroll Expense',
  INTEGRATEDPAYROLLPT = 'Payroll Payment',
  EXTERNALSPENDMONEY = 'Payroll Employee Payment',
  INTEGRATEDPAYROLLPTPAYMENT = 'Payroll Tax Payment',
  INTEGRATEDPAYROLLCN = 'Payroll Credit Note',
  CREDITNOTE_REVERSAL = 'Credit note reversal',
  CREDITNOTE_ALLOCATION = 'Credit note allocation',
  PREPAYMENT_ALLOCATION = 'Prepayment allocation',
  OVERPAYMENT_ALLOCATION = 'Overpayment allocation',
}
